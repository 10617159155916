<header class="ticketbuy-heading">Fahrausweis ausstellen</header>
<div class="main-wrapper container-fluid">

  <form (ngSubmit)="onSubmit()" *ngIf="step === 0 || step === 1 && !isIssuance" [formGroup]="drivingLicenseForm"
    class="form-content was-validated">

    <br>

    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
      <div class="card border-0 card_shadow">
        <div class="card-body">

          <div class="row mx-auto mb-3">
            <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
              <div class="col-elementLabel">
                <div class="elementValue">
                  <label for="driving-license-id">ID</label>
                </div>
              </div>
            </div>

            <div class="col-lg-8 col-md-8 col-sm-8">
              <div class="col-elementValue">
                <div class="elementValue">
                  <p>{{credSubject.drivingLicenseId}}</p>
                  <!-- <select formControlName="honorificPrefix" id="honorific-prefix" class="form-select">
                  <option value="Mr.">Herr</option>
                  <option value="Mrs.">Frau</option>
                </select> -->
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-auto mb-3">
            <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
              <div class="col-elementLabel">
                <div class="elementValue">
                  <label for="driving-license-class-name" class="">Fahrerlaubnisklasse</label>
                </div>
              </div>
            </div>

            <div class="col-lg-8 col-md-8 col-sm-8">
              <div class="col-elementValue">
                <div class="elementValue">
                  <select formControlName="drivingLicenseClassName" id="driving-license-class-name" class="form-select">
                    <option *ngFor="let cl of dlClasses" [value]="cl">{{cl}}</option>
                    <!-- <option value="Mr.">Herr</option>
                  <option value="Mrs.">Frau</option> -->
                  </select>
                  <div
                    *ngIf="drivingLicenseForm.controls['drivingLicenseClassName'].errors?.['required'] && drivingLicenseForm.controls['drivingLicenseClassName'].touched"
                    class="error">
                    Fahrerlaubnisklasse ist ein Pflichtfeld.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-auto mb-3">
            <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
              <div class="col-elementLabel">
                <div class="elementValue">
                  <label for="validity-period">Gültigkeitsdauer</label>
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8">
              <div class="col-elementValue">
                <div class="elementValue">
                  <select formControlName="validityPeriod" id="validity-period" class="form-select">
                    <option *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]" [value]="i">{{i}}</option>
                    <!-- <option value="Mr.">Herr</option>
                  <option value="Mrs.">Frau</option> -->
                  </select>
                  <div
                    *ngIf="drivingLicenseForm.controls['validityPeriod'].errors?.['required'] && drivingLicenseForm.controls['validityPeriod'].touched"
                    class="error">
                    Gültigkeitsdauer ist ein Pflichtfeld.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-auto mb-3">
            <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
              <div class="col-elementLabel">
                <div class="elementValue">
                  <label for="issuance-date">Ausgabedatum</label>
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8">
              <div class="col-elementValue">
                <div class="elementValue">
                  <input formControlName="issuanceDate" id="issuance-date" type="text" class="form-control" required>
                  <div
                    *ngIf="drivingLicenseForm.controls['issuanceDate'].errors?.['required'] && drivingLicenseForm.controls['issuanceDate'].touched"
                    class="error">
                    Ausgabedatum ist ein Pflichtfeld.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p class="flex-wrapper" *ngIf="!drivingLicenseForm.valid">Bitte füllen Sie die Pflichtfelder aus, um
            fortzufahren.</p>

        </div>
      </div>
    </div>
    <div class="flex-wrapper">
    </div>


  </form>

  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="isIssuance">
    <div class="card border-0 card_shadow">
      <div class="card-body">
        <h3 style="text-align: center;">Ihr {{credentialType | getLang}} wird nun ausgestellt.
        </h3>

      </div>
    </div>
  </div>
  <app-presentation *ngIf="proofArray.length >= 3" [proofArray]="proofArray"></app-presentation>
  <app-showqrcode *ngIf="showQrCode" [verificationUrl]="appUrl" class="vh-73"></app-showqrcode>
  <div class="flex-wrapper" style="margin: auto 0;">
    <ng-container *ngIf="true">
      <button *ngIf="step === 0" (click)="fillForm()" class="link-box-white" type="button">Form füllen</button>
      <button *ngIf="!showQrCode" (click)="onSubmit()" [disabled]="!drivingLicenseForm.valid"
        [ngClass]="showQrCode ? 'link-box-white vh40': 'link-box-white'" name="next">{{buttonText}}
      </button>
    </ng-container>
  </div>
</div>
  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="isIssuance">
    <div class="card border-0 card_shadow">
      <div class="card-body">
        <h3 style="text-align: center;">Ihr {{credentialType | getLang}} wird nun ausgestellt.
        </h3>

      </div>
    </div>
  </div>
  <dialog id="dialog-box-alert">
    <h3>Es ist ein Fehler aufgetreten</h3>
    <p>{{alertMessage}}</p>
    <p *ngIf="hintMessage.length > 0">{{hintMessage}}</p>
    <div class="inline">
      <button (click)="onDialogBack()" class="link-box-white">Ok</button>
    </div>
  </dialog>
  <ng-container *ngFor="let message of messageArray">
    <app-toaster *ngIf="message.length > 0" [messageText]="message" [status]="statusOk"></app-toaster>
  </ng-container>
  <!-- Footer -->
  <!--<app-footer [footerButtons]="footerButtons" [selectedTicket]="selectedTicket"></app-footer>-->