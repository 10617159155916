import { Component, OnInit, SimpleChanges } from '@angular/core';
import { DataService } from '../../services/data.service';
import { CreateMessageService } from '../../services/create-message.service';
import { LocalDataStorageService } from '../../services/local-data-storage.service';
import { EnvService } from '../../../environments/environment';
import { CredentialBuilderService } from '../../services/credential-builder.service';
import { MessageContainer } from '../../models/messages/messageContainer.model';
import { KdkBiometricPhotoKModel } from '../../models/kaprion/vc/kdk-biometric-photo-k.model';
import { SocialPassModel } from '../../models/kaprion/vc/social-pass.model';
import { EvidenceModel } from '../../models/w3c/vcdm/evidence.model';
import { WohngeldbescheidModel } from '../../models/kaprion/vc/wohngeldbescheid.model';
import { VerifiableCredential1xModel } from '../../models/w3c/vcdm/verifiable-credential-1x.model';
import { Issuer } from '../../models/issuer.model';
import { Router } from '@angular/router'

@Component({
  selector: 'app-dresdenpass',
  templateUrl: './dresden-pass.component.html',
  styleUrls: ['./dresden-pass.component.scss']
})
export class DresdenPassComponent implements OnInit {

  // site related objects
  content: string = "";
  buttonText: string = "Herausgabe des Sozialpasses anfragen";
  buttonCancel: string = "Abbruch";
  isWaitingForResponse: boolean = false;
  statusOk: boolean = false;

  htmlElementButtonCancel: HTMLElement | null = null;

  // process related objects
  sessionId: string = "";
  credentialArray: any[] = [];
  presentationID: string = "";
  imageSource: string = "";
  isWG: boolean = false;
  proofArray: string[] = [];
  messageText: string = "";
  messageArray: string[] = [];

  credSubject: SocialPassModel = {
    id: "iss:REPLACE_ME_HOLDER",
    isProtectedByCRE: true
  }

  evidence: EvidenceModel[] = []

  wg = {
    issued: "",
    expires: "",
    wohngeld: {
      typ: "",
      complies: "",
      ihreAngaben: {
        bruttoEinkommen: "",
        bruttoMiete: "",
        anzWohngeldBerechtigt: 0,
        kinderbetreuungskosten: "",
        mietenstufe: 0,
      },
      berechnung: {
        werbungskosten: "",
        gesamtEinkommenNachAbzuegen: "",
        berueksichtigungMiete: "",
        betriebskostenAbzug: "",
        heizkostenpauschale: "",
      },
      gesamtbetrag: ""
    }
  }

  appUrl: string = "";
  showQrCode: boolean = false;
  isRequestPresentation: boolean = false;
  isStep: boolean = false;
  purposeObjectArray: {[key: string]: any}[] = [];

  private requiredKDK = "KDKBiometricPhotoK";
  private requiredWG = "Wohngeldbescheid";
  private state = StateMachine.REQ_PRESENTATION;

  constructor(
    private dataService: DataService,
    private createMessageService: CreateMessageService,
    private localDataStorageService: LocalDataStorageService,
    private envService: EnvService,
    private router: Router,
    private csb: CredentialBuilderService) {
  }

  ngOnInit(): void {
    this.sessionId = crypto.randomUUID()
    this.localDataStorageService.setData("sessionId", this.sessionId);
    //this.htmlElementButtonCancel = document.getElementById("buttonCancel");
    this.state = StateMachine.REQ_PRESENTATION;
    this.localDataStorageService.setData("navigationalFragment", "/ddp");
  }

  /**
   * Resets all values to default
   */
  reset() {
    this.sessionId = this.localDataStorageService.getData("sessionId");
    this.buttonText = "Herausgabe des Sozialpasses anfragen";
    this.credentialArray = [];
    this.presentationID = "";
    //this.htmlElementButtonCancel!.style.display = 'none';
    this.state = StateMachine.REQ_PRESENTATION;
    this.appUrl = "";
    this.showQrCode = false;
    this.proofArray = [];
    this.evidence = []
    try {
      const pic = document.querySelector('#pic');
      if (pic !== null) pic.remove();
    } catch (e) { }
    try {
      const table = document.querySelector('#table');
      if (table !== null) table.remove();
    } catch (e) { }
  }

  /**
   * Submits a request which depends on current state
   */
  onSubmit() {
    if (StateMachine.REQ_PRESENTATION === this.state) { this.requestPresentation() }
    else if (StateMachine.SEND_SP === this.state) { this.issueSP() }

  }

  /**
   * Sends a present proof task to CS to request a credential from holder
   * @private
   */
  private genPPPMessage() {
    return new Promise((resolve, reject) => {
      this.presentationID = crypto.randomUUID();
      this.localDataStorageService.setData("ticketIdArray", [this.presentationID]); // presentation_definition.id
      this.localDataStorageService.setData("taskType", "de.kaprion.ppp.s2p");
      this.createMessageService.prepareMessageV2(Issuer.getLHDIssuer(), this.presentationID).then(msg => {
        if (msg instanceof MessageContainer) {
          if (msg.getMessages().length < msg.getTaskAmount()) {
            msg.setTaskAmount(msg.getMessages().length);
          }

          msg = JSON.stringify(msg, (key, value) => {
            if (value === undefined || value === null) {
              return undefined;
            }
            // Filtering null and undefined values from arrays
            if (Array.isArray(value)) {
              return value.filter((item) => item !== null && item !== undefined);
            }
            return value;
          });


          this.dataService.sendExpectJSON(msg, this.envService.env['CS_URL'] + '/initSessionValidation').then(response => {
            let attach = {}
            if (response['attachments'] !== undefined && !Array.isArray(response['attachments'])) {
              attach = response['attachments']['data']['json']['verifiableCredential'];
            } else {
              attach = response['attachments'][0]['data']['json']['verifiableCredential'];
            }
            if (Array.isArray(attach)) {
              this.credentialArray = attach;
            }
            else {
              this.credentialArray.push(attach);
            }
            console.log(this.credentialArray);
            if (StateMachine.REQ_PRESENTATION === this.state) {
              this.credentialArray.forEach(credential => {
                if ((<VerifiableCredential1xModel>credential).type.includes(this.requiredKDK)) {
                  this.processReceivedKDKVC(<VerifiableCredential1xModel>credential);
                }
                if ((<VerifiableCredential1xModel>credential).type.includes(this.requiredWG)) {
                  this.processReceivedWGVC(<VerifiableCredential1xModel>credential);
                }
              });
              if (this.proofArray.length === 2) {
                this.state = StateMachine.SEND_SP;
              }
            }
            else { console.warn("Unexpected call of Present Proof Protocol."); }
          }).catch((e) => {

            console.warn("Rejected: " + e);
          });
        }
      });
      console.warn(this.presentationID)
      this.appUrl = this.envService.env['VCISS_URL'] + "/verify" +
        "?_oobid=" + this.presentationID +
        "&credentialProviderLink=" + this.envService.env['VCISS_URL'] + "/validation";
      this.isStep = !this.isStep;
      this.showQrCode = true;
      this.isRequestPresentation = true;
      resolve(this.state);
    });
  }

  private requestPresentation() {
    //this.htmlElementButtonCancel!.style.display = 'block';
    this.localDataStorageService.setData("credentialArray", [this.requiredKDK, this.requiredWG]); // credential to be requested
    this.genPPPMessage();
  }

  /**
   * Side manager for stage KDK
   *
   * <ul>
   *   <li>displays a QR code that needs to be scanned by the holder</li>
   *   <li>waits for response</li>
   *   <li>changes QR Code to image object</li>
   * </ul>
   * @private
   */

  /**
   * Update Screen and prepare for next step
   *
   * @param credential the received credential
   * @private
   */
  private processReceivedKDKVC(credential: VerifiableCredential1xModel) {
    let evidence: EvidenceModel = {
      docId: (' ' + credential.id).slice(1),
      documentPresence: 'Digital',
      evidenceDocument: 'KDKBiometricPhotoK',
      id: '__HOME_URL__/PhotoVerification/' + crypto.randomUUID(),
      subjectPresence: 'Physical',
      type: ['DocumentVerification'],
      verifier: 'https://demo.shop.it.kaprion.net/samples/KAPRION/SysActor/8506cbb4-c8d0-4b34-8f93-c5638893f0e2'
    }
    this.proofArray.push(evidence.evidenceDocument);
    this.evidence.push(evidence), // make copy of KDK credential id string and store it in evidence field
      this.displayBiometricPhoto(<KdkBiometricPhotoKModel>credential.credentialSubject);
      this.state = StateMachine.REQ_PRESENTATION;
  }

  /**
   * Extract image from credential and place it into html-img tag
   *
   * @param kdkBiometricPhotoK the KdkBiometricPhotoKModel object with the received credential
   * @private
   */
  private displayBiometricPhoto(kdkBiometricPhotoK: KdkBiometricPhotoKModel) {
    // Normally, at this point you have to check if the mimetype is present in the `img` property and if it is the same, but we'll leave that out for now.
    this.imageSource = (kdkBiometricPhotoK.img.includes(kdkBiometricPhotoK.encodingFormat)) ? kdkBiometricPhotoK.img : "data:" + kdkBiometricPhotoK.encodingFormat + ";base64," + kdkBiometricPhotoK.img;
    //let html = `<img src="${image}" class="rounded mx-auto d-block" style="min-width: 250px; max-width: 300px;" alt="Biometrisches Bild" id="pic"/>`;
    //return this.htmlDomElementOfString(html);
  }

  /**
   * Side manager for stage Wohngeldbescheid
   *
   * <ul>
   *   <li>displays a QR code that needs to be scanned by the holder</li>
   *   <li>waits for response</li>
   *   <li>changes QR Code to table view</li>
   * </ul>
   * @private
   */

  /**
   * Update Screen and prepare for next step
   *
   * @param credential the received credential
   * @private
   */
  private processReceivedWGVC(credential: VerifiableCredential1xModel) {
    this.isWG = true;
    this.showQrCode = false;
    let evidence: EvidenceModel = {
      docId: (' ' + credential.id).slice(1),
      documentPresence: 'Digital',
      evidenceDocument: 'Wohngeldbescheid',
      id: '__HOME_URL__/Verification/' + crypto.randomUUID(),
      subjectPresence: 'Physical',
      type: ['DocumentVerification'],
      verifier: 'https://demo.shop.it.kaprion.net/samples/KAPRION/SysActor/8506cbb4-c8d0-4b34-8f93-c5638893f0e2'
    }
    this.evidence.push(evidence),
    this.proofArray.push(evidence.evidenceDocument);
    this.displayWG(credential);
    try {
      const el = document.querySelector('#pic');
      el!.remove();
    } catch (e) {
      this.state = StateMachine.SEND_SP;
     }
  }

  /**
   * to format current date time to dd.MM.yyyy HH:mm:ss
   */
  private formatDateTime(dateTime: Date): string {
    return dateTime.toLocaleDateString('de-DE', { year: 'numeric', month: 'numeric', day: 'numeric' }) + ' ' +
      dateTime.toLocaleTimeString('de-DE') + ' Uhr';
  }


  /**
   * Generates Table view for Wohngeldbescheid
   *
   * @param credential the full W3C Verifiable Credential JSON
   * @private
   */
  private displayWG(credential: VerifiableCredential1xModel) {
    let wohngeld: WohngeldbescheidModel = <WohngeldbescheidModel>credential.credentialSubject;
    let formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR'
    });
    this.wg.issued = this.formatDateTime(new Date(credential.issuanceDate));
    this.wg.expires = this.formatDateTime(new Date(credential.expirationDate!));
    this.wg.wohngeld.complies = (wohngeld.erstantrag) ? 'ja' : 'nein';
    this.wg.wohngeld.ihreAngaben.bruttoEinkommen = formatter.format(wohngeld.ihreAngaben.bruttoEinkommen);
    this.wg.wohngeld.ihreAngaben.bruttoMiete = formatter.format(wohngeld.ihreAngaben.bruttoMiete);
    this.wg.wohngeld.ihreAngaben.anzWohngeldBerechtigt = wohngeld.ihreAngaben.anzWohngeldBerechtigt;
    this.wg.wohngeld.ihreAngaben.kinderbetreuungskosten = formatter.format(wohngeld.ihreAngaben.kinderbetreuungskosten);
    this.wg.wohngeld.ihreAngaben.mietenstufe = wohngeld.ihreAngaben.mietenstufe;
    this.wg.wohngeld.berechnung.werbungskosten = formatter.format(wohngeld.berechnung.werbungskosten);
    this.wg.wohngeld.berechnung.gesamtEinkommenNachAbzuegen = formatter.format(wohngeld.berechnung.gesamtEinkommenNachAbzuegen);
    this.wg.wohngeld.berechnung.berueksichtigungMiete = formatter.format(wohngeld.berechnung.berueksichtigungMiete);
    this.wg.wohngeld.berechnung.heizkostenpauschale = formatter.format(wohngeld.berechnung.heizkostenpauschale);
    this.wg.wohngeld.gesamtbetrag = formatter.format(wohngeld.gesamtbetrag);
  }

  /**
   * Issuance of social pass credential
   * @private
   */
  private issueSP() {
    this.isWG = false;
    this.imageSource = "";
    this.showQrCode = true;
    this.isRequestPresentation = false;

    let credentialContext = this.envService.env['CS_URL'] + "/credentialSubject/v6";
    let id = this.envService.env['HOME_URL'] + "/samples/KAPRION/DresdenPass/" + crypto.randomUUID();
    let credential = this.csb
      .id(id)
      .addContext(credentialContext)
      .addType("SocialPass")
      .issuanceDate()
      .expirationDate(this.csb.getDateTime(true, 1))
      .credentialSubject(this.credSubject)
      .evidence(this.evidence)
      .build();

    // send VC

    this.localDataStorageService.setData("credentialArray", [credential]);
    this.localDataStorageService.setData("ticketIdArray", [id]);
    this.localDataStorageService.setData("taskType", "de.kaprion.icp.s2p");
    this.localDataStorageService.setData("requestPresentation", [this.requiredKDK, this.requiredWG]);

    let message = this.createMessageService.prepareMessage(Issuer.getLHDIssuer(),true, false,true).then(msg => {
      if (msg instanceof MessageContainer) {
        if (msg.getMessages().length < msg.getTaskAmount()) {
          msg.setTaskAmount(msg.getMessages().length);
        }
        msg = JSON.stringify(msg, (key, value) => {
          if (value === undefined || value === null) {
            return undefined;
          }
          // Filtering null and undefined values from arrays
          if (Array.isArray(value)) {
            return value.filter((item) => item !== null && item !== undefined);
          }
          return value;
        });

        this.dataService.send(msg, this.envService.env['VCISS_URL'] + '/initSession').then(response => {
          if (response['ok']) {
            this.statusOk = true;
            this.messageText = "Ihr Sozialpass-Nachweis wurde nun ausgestellt.";
            this.messageArray.push(this.messageText);
          } else {
            this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['status'];
            this.statusOk = false;
            this.messageArray.push(this.messageText);
          }
          this.credentialArray = [];
          // alert(response) // TODO Updatet for better Message -> Dialog Box
          this.reset();
        }).catch((e) => {

          console.warn("Rejected: " + e);
        });
      }
      this.appUrl = this.envService.env['VCISS_URL'] + "/issue" +
        "?_oobid=" + this.localDataStorageService.getData("oobid") +
        "&credentialProviderLink=" + this.envService.env['VCISS_URL'] + "/issuance";
      this.showQrCode = true;
    });
  }

  onClickBack() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).showModal();
  }

  // request confirmation
  onDialogCancel() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).close();
  }

  onDialogOk() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).close();
    this.reset();
    this.localDataStorageService.setData("navigationalFragment", "");
    this.router.navigate(["home"]);
  }

  /**
   * Generates QR code images from URL
   *
   * @param url a URL string
   * @private
   */
}

enum StateMachine {
  REQ_PRESENTATION,
  SEND_SP
}
