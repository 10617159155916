<header class="heading">Erteilung des SEPA-Lastschriftmandats</header>

<div class="main-wrapper container-fluid" style="display: block;">
    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
        <div class="card border-0 card_shadow">
            <div class="card-body">
                <p>Für den Anwendungsfall SEPA-Lastschriftmandat werden die folgenden Nachweise abgefragt.
                </p>
                <ol>
                    <li>KDK Person</li>
                    <li>KDK Adresse</li>
                    <li>KDK Altersnachweis</li>
                    <p>Nach der erfolgreichen Präsentation wird Ihr SEPA-Lastschriftmandat ausgestellt.</p>
                </ol>
            </div>
        </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="isSEPA">
      <div class="card border-0 card_shadow">
          <div class="card-body">
              <h3 style="text-align: center;">Ihr SEPA-Lastschriftmandat wird nun ausgestellt.
              </h3>

          </div>
      </div>
  </div>

    <form (ngSubmit)="onFinalSubmit()" [formGroup]="sepaForm" class="form-content was-validated"
        *ngIf="(isInput || isDisplaySepa) && !showQrCode ">
        <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
            <div class="card border-0 card_shadow">
                <div class="card-body">
                  <div class="row mx-auto mb-3">
                    <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                        <div class="col-elementLabel">
                            <div class="elementValue">
                                <label for="repeatable-payment">Kreditinstitut:</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <div class="col-elementValue">
                            <div class="elementValue">
                                <input formControlName="creditInstitution" id="credit-institution" type="text"
                                    class="form-select" required>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-auto mb-3">
                  <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                      <div class="col-elementLabel">
                          <div class="elementValue">
                              <label for="repeatable-payment">IBAN:</label>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-8">
                      <div class="col-elementValue">
                          <div class="elementValue">
                              <input formControlName="iban" id="iban" type="text"
                                  class="form-select" required>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row mx-auto mb-3">
                <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                    <div class="col-elementLabel">
                        <div class="elementValue">
                            <label for="bic">BIC:</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <div class="col-elementValue">
                        <div class="elementValue">
                            <input formControlName="bic" id="bic" type="text"
                                class="form-select" required>
                        </div>
                    </div>
                </div>
            </div>
                    <div class="row mx-auto mb-3">
                        <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                            <div class="col-elementLabel">
                                <div class="elementValue">
                                    <label for="repeatable-payment">Wiederkehrende Zahlungen:</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <div class="col-elementValue">
                                <div class="elementValue">
                                    <select formControlName="repeatablePayment" id="repeatable-payment" type="text"
                                        class="form-select" required>
                                        <option value="true">Ja</option>
                                        <option value="false">Nein</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-auto mb-3">
                        <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                            <div class="col-elementLabel">
                                <div class="elementValue">
                                    <label for="intended-use">Verwendungszweck:</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <div class="col-elementValue">
                                <div class="elementValue">
                                    <input formControlName="intendedUse" id="intended-use" type="text"
                                    [ngClass]="(sepaForm.controls['intendedUse'].status.toLowerCase() === 'valid' || !ibanInvalid) ? 'form-control is-valid' : 'form-control is-invalid'" required>
                                </div>
                                <div *ngIf="sepaForm.controls['intendedUse'].errors?.['required'] && sepaForm.controls['intendedUse'].touched"
                                    class="error">
                                    Verwendungszweck ist ein Pflichtfeld.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>


    <app-presentation *ngIf="proofArray.length > 0" [proofArray]="proofArray"></app-presentation>
    <div style="display: block;" class="vh-73">
        <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="showQrCode">
            <div class="card border-0 card_shadow">
                <!--<div class="card-body" id="content-box">-->
                <app-showqrcode class="qr-code" *ngIf="showQrCode" [verificationUrl]="appUrl"
                    [isRequestPresentation]="isRequestPresentation"></app-showqrcode>
            </div>
        </div>
        <div [ngClass]="{'flex-wrapper': true}">
            <button *ngIf="!showQrCode && sepaForm.valid && isInput" type="submit" (click)="onFinalSubmit()"
                class="link-box-white">{{buttonText}}</button>
            <button *ngIf="!showQrCode && !isInput && isRequestPresentation" type="submit" (click)="onFinalSubmit()"
                class="link-box-white">{{buttonTextNew}}</button>
            <!--<button *ngIf="!showQrCode && !isInput" (click)="requestPresentation()" class="link-box-white">Prozess starten</button>-->
            <button (click)="fillForm()" class="link-box-white" *ngIf="isInput === true">Formular füllen</button>
            <button (click)="onClickBack()" class="link-box-white"
                *ngIf="!showQrCode && (isDisplaySepa && !isRequestPresentation)">Zurück</button>
            <!--<button *ngIf="!showQrCode && !isInput && isSEPA" (click)="onClickCancel()"
                class="link-box-white">Abbrechen</button>-->
        </div>
    </div>



</div>

<dialog id="dialog-box">
    <h3>Möchten Sie die Eingabe Ihrer Daten beenden und zur Credentialauswahl zurückkehren?</h3>
    <div class="inline">
        <button (click)="onDialogCancel()" class="link-box-white">Nein</button>
        <button (click)="onDialogOk()" class="link-box-white">Ja</button>
    </div>
</dialog>
<!-- <div class="main-wrapper container-fluid" *ngIf="SEPA.issued.length !== 0 && isSEPA === true">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mx-auto p-3 pt-3">
        <div class="card border-0 card_shadow">
            <div class="card-body">
                <div id="content-box">
                    <div id="response1">
                        <div class="table-responsive-sm" id="table">
                            <table class="table table-hover table-md">
                                <tr>
                                    <td>G&uuml;ltig von:</td>
                                    <td>{{SEPA.issued}}</td>
                                </tr>
                                <tr>
                                    <td>G&uuml;ltig bis:</td>
                                    <td>{{SEPA.expires}}</td>
                                </tr>

                                <tr>
                                    <td colspan="2">
                                        <h3 class="mt-md-4">Ihre Angaben</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td>ID:</td>
                                    <td>
                                        {{SEPA.id}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Kreditinstitut:</td>
                                    <td>
                                        {{SEPA.creditInstitution}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>IBAN:</td>
                                    <td>
                                        {{SEPA.iban}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>BIC:</td>
                                    <td>{{SEPA.bic}}</td>
                                </tr>
                                <tr>
                                    <td>wiederkehrende Einzugserlaubnis:</td>
                                    <td>
                                        {{SEPA.repeatablePayment === true? "Ja": "Nein"}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Verwendungszweck:</td>
                                    <td>{{SEPA.intendedUse}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<ng-container *ngFor="let message of messageArray">
    <app-toaster *ngIf="message.length > 0" [messageText]="message" [status]="statusOk"></app-toaster>
</ng-container>