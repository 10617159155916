import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-display-json',
  templateUrl: './display-json.component.html',
  styleUrls: ['./display-json.component.scss']
})
export class DisplayJsonComponent implements OnInit {

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    if (window.location.pathname.includes("credentials/v1")) {
      let urlParts = window.location.toString().split('credentials/v1');
      if (urlParts.length > 1 && urlParts[1].lastIndexOf('.json') > -1) {
        let urlString = urlParts[0] + 'assets/credentials/v1/' + urlParts[1];
        const obj = this.dataService.retrieveFile(urlString).then(result => {
          const file = new Blob([JSON.stringify(result)], { type: "application/json" });
          let url = window.URL.createObjectURL(file);
          window.location.href = url;
        })

        //this.router.navigateByUrl(urlString)
      } else {
        let urlString = window.location.origin +  '/assets/credentials/v1/' + 'id-ideal-ld-doc-v3.jsonld'
        const obj = this.dataService.retrieveFile(urlString).then(resultObj => {
          const file = new Blob([JSON.stringify(resultObj)], {type: "application/json"});
          let url = window.URL.createObjectURL(file);
          window.location.href = url;
        })
      }
    }

  }

}
