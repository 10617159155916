import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EvidenceModel } from 'src/app/models/w3c/vcdm/evidence.model';
import { CreateMessageService } from 'src/app/services/create-message.service';
import { CredentialBuilderService } from 'src/app/services/credential-builder.service';
import { DataService } from 'src/app/services/data.service';
import { LocalDataStorageService } from 'src/app/services/local-data-storage.service';
import { EnvService, environment } from 'src/environments/environment';
import { VerifiableCredential1xModel } from 'src/app/models/w3c/vcdm/verifiable-credential-1x.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Issuer } from 'src/app/models/issuer.model';
import { MessageContainer } from 'src/app/models/messages/messageContainer.model';
import { GetLangPipe } from 'src/app/pipe/get-lang.pipe';
import { JungheinrichModel } from 'src/app/models/kaprion/vc/jungheinrich.model';

@Component({
  selector: 'app-jungheinrich',
  templateUrl: './jungheinrich.component.html',
  styleUrls: ['./jungheinrich.component.scss']
})
export class JungheinrichComponent implements OnInit {

  messageText: string = "";
  proofArray: string[] = [];
  sessionId: string = "";
  oobid: string = "";
  credentialArray: any[] = [];
  evidence: EvidenceModel[] = [];
  showQrCode = false;
  buttonText: string = "Fahrzeug ausleihen";
  credentialSubjects: { [key: string]: any }[] = [];
  appUrl: string = ""
  isRequestPresentation: boolean = true;
  credSubject: JungheinrichModel;
  requestPresentation: string[] = ["KommPassPerson", "KommPassAdresse", "KommPassGeburtsurkunde", "DrivingLicense", "BADA"];
  statusOk: boolean = false;
  alertMessage: string = "";
  hintMessage: string = "";
  isIssuance: boolean = false;
  generatedCredential: VerifiableCredential1xModel | null = null;
  step: number = 0;
  heading = "Los geht's!";
  imgURL = environment.imgURL;
  purposeObjectArray: { [key: string]: any }[] = [];
  enabledVehicle: Vehicles | string | null = null;
  vehicleTypes = [
    {
      name: "Elektro-Niederhubwagen",
      selected: true,
      short: "EN"
    },
    {
      name: "Elektro-Hochhubwagen",
      selected: false,
      short: "EH"
    },
    {
      name: "Elektrostapler",
      selected: false,
      short: "ES"
    },
    {
      name: "Schubmaststapler",
      selected: false,
      short: "ST"
    },
    {
      name: "Schlepper",
      selected: false,
      short: "SP"
    },
    {
      name: "Dieselstapler",
      selected: false,
      short: "DS"
    },
    {
      name: "Gasstapler",
      selected: false,
      short: "GS"
    },
  ];
  vType: string | null = "";

  messageArray: string[] = []
  credentialType: string = "Jungheinrich";


  constructor(private dataService: DataService,
    private createMessageService: CreateMessageService,
    private localDataStorageService: LocalDataStorageService,
    private router: Router,
    private envService: EnvService,
    private csb: CredentialBuilderService) {
    this.credSubject = {
      id: "iss:REPLACE_ME_HOLDER",
      licenseAgreementId: crypto.randomUUID(),
      licensePlate: "",
      licenseFee: "10.00 EUR",
      isProtectedByCRE: true,
    };
  }

  ngOnInit(): void {
    this.sessionId = crypto.randomUUID();
    this.isIssuance = false;
    this.localDataStorageService.setData("sessionId", this.sessionId);
    this.localDataStorageService.setData("navigationalFragment", "/jungheinrich");
  }

  changeType(event: any) {
    this.vType = event.target!.value;
    this.vehicleTypes.forEach(item => {
      if (item.name !== this.vType) {
        item.selected = false;
      } else {
        item.selected = true;
      }
    });
  }

  expandRequestPresentationByPurpose() {
    this.requestPresentation.forEach((cType: string, index: number) => {
      let purposeString: string = ""
      if (cType.includes('KommPass')) {
        purposeString = 'Wir benötigen Ihre ' + cType.split('KommPass')[1] + '-Informationen, um den Nutzungsvertrag mit Ihnen abschließen zu können.'
      } else if (cType.includes('KDK')) {
        purposeString = 'Wir benötigen Ihre ' + new GetLangPipe().transform((cType.split('KDK')[1].split('K')[0])) + '-Informationen, um den Nutzungsvertrag mit Ihnen abschließen zu können.'
      } else if (cType.includes('Driving')) {
        purposeString = "Wir benötigen Ihre Fahrerlaubnis, um Ihnen ein Fahrzeug auszuleihen."
      } else {
        purposeString = 'Ihre Bankverbindung wird benötigt, um Ihnen ein Fahrzeug auszuleihen';
      }
      let obj = {
        [cType]: {
          'purpose': purposeString
        }
      }
      this.purposeObjectArray.push(obj);
      this.localDataStorageService.setData("purposeObjectArray", this.purposeObjectArray)
    })
  }

  reset(): void {
    this.sessionId = this.localDataStorageService.getData("sessionId");
    this.buttonText = "Fahrzeug ausleihen";
    this.step = 0;
    this.credentialArray = [];
    this.appUrl = "";
    this.showQrCode = false;
    this.proofArray = [];
    this.evidence = [{
      docId: '',
      documentPresence: 'Digital',
      evidenceDocument: '',
      id: this.envService.env['HOME_URL'] + '/PhotoVerification/',
      subjectPresence: 'Physical',
      type: ['DocumentVerification'],
      verifier: 'KAPRION Demo Shop User'
    }]
  }

  onSubmit(): void {
    if (this.step < 3) {
      this.buttonText = "Fahrzeug ausleihen";
    } else {
      this.buttonText = "Konto eröffnen";
    }
    this.step++;
    if (this.proofArray.length !== this.requestPresentation.length && this.step > 4) {
      this.issueCredential();
    }
  }

  checkRequestedItem(requestedItem: string) {
    return this.requestPresentation.includes(requestedItem);
  }

  issueCredential() {
    this.isRequestPresentation = false;
    let credentialContext = this.envService.env['CS_URL'] + "/credentialSubject/v7";
    let id = this.envService.env['HOME_URL'] + "/samples/KAPRION/" + "Nextbike" + "/" + crypto.randomUUID();
    this.localDataStorageService.setData("taskType", "cs.icp.split.offerCredential");
    this.localDataStorageService.setData("requestPresentation", this.requestPresentation);
    this.expandRequestPresentationByPurpose();
    let credential = this.csb
      .id(id)
      .addContext(credentialContext)
      .addType(this.credentialType)
      .issuanceDate()
      .expirationDate(this.csb.getDateTime(true, 1 / 365))
      .credentialSubject(this.credSubject)
      .evidence(this.evidence)
      .build();
    this.credentialArray.push(credential);
    this.localDataStorageService.setData("credentialArray", this.credentialArray);
    let message = this.createMessageService.prepareMessageV3(Issuer.getJungheinrichIssuer(), true, true, false).then(msg => {
      this.appUrl = this.envService.env['VCISS_URL'] + "/issue" +
        "?_oobid=" + this.localDataStorageService.getData("oobid") +
        "&credentialProviderLink=" + this.envService.env['VCISS_URL'] + "/issuance";
      this.showQrCode = true;
      if (msg instanceof MessageContainer) {
        msg = JSON.stringify(msg, (key, value) => {
          if (value === undefined || value === null) {
            return undefined;
          }
          if (Array.isArray(value)) {
            return value.filter((item) => item !== null && item !== undefined);
          }
          return value;
        });
        this.dataService.send(msg, this.envService.env['VCISS_URL'] + '/initSession', true).then((response: any) => {
          if (response['type'] === "de.kaprion.icp.s2p.issueCredential.req") {
            this.statusOk = true;
            let attach = response['credentialApplication']['verifiableCredential'];
            attach.forEach((credential: any) => {
              this.processReceivedVC(<VerifiableCredential1xModel>credential);
            });
            this.enabledVehicle = this.getRandomEnumValue(Vehicles)
            this.credSubject.licensePlate = this.enabledVehicle;
            if (this.proofArray.length >= this.requestPresentation.length) {
              this.isIssuance = true;
              let credential = this.csb
                .id(id)
                .addContext(credentialContext)
                .addType("Jungheinrich")
                .issuanceDate()
                .expirationDate(this.csb.getDateTime(true, 1))
                .credentialSubject(this.credSubject)
                .evidence(this.evidence)
                .build();
              this.localDataStorageService.setData("credentialArray", [credential]);
              this.localDataStorageService.setData("taskType", "cs.icp.split.issueCredential");
              this.showQrCode = false;
              let finalMessage = this.createMessageService.prepareMessageV3(Issuer.getLHDIssuer(), true, true, false).then(finalMsg => {
                if (finalMsg instanceof MessageContainer) {
                  finalMsg = JSON.stringify(finalMsg, (key, value) => {
                    if (value === undefined || value === null) {
                      return undefined;
                    }
                    // Filtering null and undefined values from arrays
                    if (Array.isArray(value)) {
                      return value.filter((item) => item !== null && item !== undefined);
                    }
                    return value;
                  });
                  this.dataService.send(finalMsg, this.envService.env['VCISS_URL'] + '/initSession').then((response: any) => {
                    if (response['ok']) {
                      this.statusOk = true;
                      this.isIssuance = false;
                      if (credential.type.includes("SEPADirectDebitMandate"))
                        this.generatedCredential = credential;
                      this.router.navigate(["home"])
                    } else {
                      this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['status'];
                      this.messageArray.push(this.messageText);
                      this.statusOk = false;
                    }
                  });
                }
              })
            } else {
              this.messageText = "Vorgelegte Nachweise erfüllen nicht die Anforderung zur Ausstellung des Bank-Credentials."
              this.messageArray.push(this.messageText);
              this.hintMessage = "Folgende Nachweise erfüllen nicht die Anforderungen: " + this.checkEntry()
              this.statusOk = false;
            }
          } else {
            this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['status'];
            this.messageArray.push(this.messageText);
            this.statusOk = false;
          }
          this.credentialArray = [];
          // alert(response) // TODO Updatet for better Message -> Dialog Box
          this.reset();
        }).catch((e) => {

          console.warn("Rejected: " + e);
        });
      }
    });
  }

  // get a random entry from enum and return a converted string
  getRandomEnumValue(enumeration: any): string {
    const enumValue = Object.values(enumeration)[Math.floor(Math.random() * Object.values(enumeration).length)] as any;
    let vType = this.vehicleTypes.filter((type) => type.selected === true);
    let vehicleString = enumValue + (vType.length === 1 ? vType[0].short : "");
    return vehicleString;
  }

  checkEntry() {
    return this.requestPresentation.filter(entry => !this.proofArray.includes(entry));
  }

  processReceivedVC(credential: VerifiableCredential1xModel) {
    let vcType = this.evaluateForm(credential)
    if (vcType.length > 0) {
      let evidence: EvidenceModel = {
        docId: (' ' + credential.id).slice(1),
        documentPresence: 'Digital',
        evidenceDocument: vcType,
        id: '__HOME_URL__/Verification/' + crypto.randomUUID(),
        subjectPresence: 'Physical',
        type: ['DocumentVerification'],
        verifier: 'https://demo.shop.kaprion.net/samples/KAPRION/SysActor/8506cbb4-c8d0-4b34-8f93-c5638893f0e2'
      };
      this.proofArray.push(vcType);
      this.evidence.push(evidence);
    }
  }

  evaluateForm(cred: VerifiableCredential1xModel): string {
    let foundType: string = "";
    this.requestPresentation.forEach(type => {
      if (cred.type.includes(type)) {
        foundType = type;
      }
    })
    return foundType
  }

  //abort-functionality | back-button
  onClickBack() {
    this.buttonText = "Konto eröffnen";
  }

  onClickCancel() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).showModal();
  }

  // request confirmation
  onDialogCancel() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).close();
  }

  onDialogOk() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).close();
    this.localDataStorageService.setData("navigationalFragment", "");
    this.router.navigate(["home"]);
  }

  onDialogBack() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[1] as any).close();
    this.sessionId = this.localDataStorageService.getData("sessionId");
    this.buttonText = "Eingaben prüfen";
    this.credentialSubjects = [];
    this.credentialArray = [];
    this.appUrl = "";
    this.showQrCode = false;
  }

  onDialogPresentationInvalid() {
    let dialogList = document.getElementById("dialog-box-presentation-invalid");
    (dialogList as any).close();
    this.router.navigate(['home'])
  }
}

enum Vehicles {
  A9315 = 'A9315',
  A7211 = 'A7211',
  A1217 = 'A1217'
}
