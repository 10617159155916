import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Issuer } from '../../models/issuer.model';
import { BankAccountModel } from '../../models/kaprion/vc/Bank-account.model';
import { KdkAddressKModel } from '../../models/kaprion/vc/kdk-address-k.model';
import { KdkAgeProofKModel } from '../../models/kaprion/vc/kdk-age-proof-k.model';
import { KdkPersonModel } from '../../models/kaprion/vc/kdk-person-k.model';
import { MessageContainer } from '../../models/messages/messageContainer.model';
import { EvidenceModel } from '../../models/w3c/vcdm/evidence.model';
import { VerifiableCredential1xModel } from '../../models/w3c/vcdm/verifiable-credential-1x.model';
import { CreateMessageService } from '../../services/create-message.service';
import { CredentialBuilderService } from '../../services/credential-builder.service';
import { DataService } from '../../services/data.service';
import { LocalDataStorageService } from '../../services/local-data-storage.service';
import { EnvService } from '../../../environments/environment';
import { IbanGeneratorService } from 'src/app/services/iban-generator.service';
import { GetLangPipe } from 'src/app/pipe/get-lang.pipe';

@Component({
  selector: 'app-sepa-mandate-presentation',
  templateUrl: './sepa-mandate-presentation.component.html',
  styleUrls: ['./sepa-mandate-presentation.component.scss']
})
export class SepaMandatePresentationComponent implements OnInit {

  credentialSubjects: { [key: string]: any }[] = [];
  messageText: string = "";
  isWaitingForResponse: boolean = false;
  proofArray: string[] = [];
  sessionId: string = "";
  ticketIdArray: string[] = [];
  oobid: string = "";
  oobids: string[] = [];
  credentialArray: any[] = [];
  SEPA = {
    issued: "",
    expires: "",
    id: "",
    creditInstitution: "",
    iban: "",
    bic: "",
    repeatablePayment: false,
    intendedUse: ""
  }

  evidence: EvidenceModel[] = [];

  requestPresentation: string[] = ["KDKPersonK", "KDKAgeProofK", "KDKAddressK"];
  isIssuance = false;
  /**
   * Credential subject variable with default values
   */
  credSubject: BankAccountModel = {
    id: "iss:REPLACE_ME_HOLDER",
    creditInstitution: "",
    iban: "", //valid Iban
    bic: "",
    isProtectedByCRE: true,
    repeatablePayment: false,
    intendedUse: ""
  };


  alertMessage = "";
  hintMessage = "";
  ibanInvalid = false;

  sepaForm: FormGroup = new FormGroup({
    creditInstitution: new FormControl('', [Validators.required]),
    iban: new FormControl('', [Validators.required]),
    bic: new FormControl('', [Validators.required]),
    repeatablePayment: new FormControl('', [
      Validators.required
    ]),
    intendedUse: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ])),
    // sepa: new FormControl(''),
    // dresdenPass: new FormControl('')
  });

  statusOk: boolean = false;
  presentationID: string = "";
  state: StateMachine = StateMachine.REQ_PRESENTATION;
  isSEPA: boolean = false;
  isRequestPresentation: boolean = false;
  showQrCode: boolean = false;
  appUrl: string = "";
  requiredSEPA: string = "SEPADirectDebitMandate";
  isInput: boolean = false;
  buttonText: string = "Eingaben prüfen";
  buttonTextNew: string = "SEPA-Lastschriftmandat ausstellen";

  isDisplaySepa = false;
  isDisplayPerson = false;
  isDisplayAddress = false;
  isDisplayAgeProof = false;

  messageArray: string[] = [];

  purposeObjectArray: {[key: string]: any}[] = [];

  constructor(
    private dataService: DataService,
    private createMessageService: CreateMessageService,
    private localDataStorageService: LocalDataStorageService,
    private router: Router,
    private envService: EnvService,
    private csb: CredentialBuilderService,
    private ibanGenService: IbanGeneratorService) {
  }

  ngOnInit(): void {
    this.sessionId = crypto.randomUUID();
    this.localDataStorageService.setData("sessionId", this.sessionId);
    this.isInput = true;
    this.state = StateMachine.INPUT_SEPA;
  }

  /**
   * Returns an ISO 8601 date-time string
   * @param deltaYear
   */
  getDateTime(deltaYear: number = 0) {
    let date = new Date();
    if (deltaYear > 0) {
      date.setHours(0, 0, 0, 0); // reset hours, minutes, seconds and milliseconds
      date.setFullYear(date.getFullYear() + deltaYear);
    }
    return date.toISOString();
  }

  /**
   * Resets the credential to default values
   */
  resetCredential() {
    this.credSubject = {
      id: "iss:REPLACE_ME_HOLDER",
      creditInstitution: "IDIdeal Bank",
      iban: "DE88100900001234567892",
      bic: "IDI18500111",
      repeatablePayment: false,
      intendedUse: ""
      // isProtectedByCRE: true
    };
  }

  /**
   * Builds a WohngeldbescheidModel Credential
   * TODO("add ngForms")
   */
  fillCredential() {
    this.oobid = crypto.randomUUID();
    this.oobids.push(this.oobid);

    this.credSubject.creditInstitution = this.sepaForm.controls['creditInstitution'].value;
    this.credSubject.iban = this.sepaForm.controls['iban'].value;
    this.credSubject.bic = this.sepaForm.controls['bic'].value;
    this.credSubject.repeatablePayment = this.sepaForm.controls['repeatablePayment'].value;
    this.credSubject.intendedUse = this.sepaForm.controls['intendedUse'].value;


    let credentialContext = this.envService.env['CS_URL'] + "/credentialSubject/v6";
    let id = this.envService.env['HOME_URL'] + "/samples/KAPRION/SEPADirectDebitMandate/" + this.oobid;

    let credential = this.csb
      .id(id)
      .addContext(credentialContext)
      .addType("SEPADirectDebitMandate")
      .issuanceDate()
      .expirationDate()
      .evidence(this.evidence)
      .credentialSubject(this.credSubject)
      .build();

    this.ticketIdArray.push(id);
    this.credentialArray.push(credential);
  }

  // private genPPPMessage() {
  //   this.presentationID = crypto.randomUUID();
  //   this.localDataStorageService.setData("ticketIdArray", [this.presentationID]); // presentation_definition.id
  //   this.localDataStorageService.setData("taskType", "de.kaprion.ppp.s2p");
  //   this.createMessageService.prepareMessageV2(Issuer.getEShopIssuer(), this.presentationID).then((msg: any) => {
  //     if (msg instanceof MessageContainer) {
  //       if (msg.getMessages().length < msg.getTaskAmount()) {
  //         msg.setTaskAmount(msg.getMessages().length);
  //       }

  //       msg = JSON.stringify(msg, (key, value) => {
  //         if (value === undefined || value === null) {
  //           return undefined;
  //         }
  //         // Filtering null and undefined values from arrays
  //         if (Array.isArray(value)) {
  //           return value.filter((item) => item !== null && item !== undefined);
  //         }
  //         console.warn(value)
  //         return value;
  //       });


  //       this.dataService.sendExpectJSON(msg, this.envService.env['CS_URL'] + '/initSessionValidation').then((response: any) => {

  //         let attach = response['attachments']['data']['json']['verifiableCredential'];
  //         if (Array.isArray(attach)) {
  //           this.credentialArray = attach;
  //         }
  //         else {
  //           this.credentialArray.push(attach);
  //         }
  //         console.log(this.credentialArray);
  //         if (StateMachine.REQ_PRESENTATION === this.state) {
  //           this.credentialArray.forEach(credential => {
  //             if ((<VerifiableCredential1xModel>credential).type.includes(this.requiredPerson)) {
  //               this.processReceivedPerson(<VerifiableCredential1xModel>credential);
  //             } else if ((<VerifiableCredential1xModel>credential).type.includes(this.requiredAddress)) {
  //               this.processReceivedAddress(<VerifiableCredential1xModel>credential);
  //             } else if ((<VerifiableCredential1xModel>credential).type.includes(this.requiredAgeProof)) {
  //               this.processReceivedAgeProof(<VerifiableCredential1xModel>credential);
  //             } else if ((<VerifiableCredential1xModel>credential).type.includes('BADA')) {
  //               this.processReceivedBADA(<VerifiableCredential1xModel>credential);
  //             }
  //           })
  //         }
  //         else { console.warn("Unexpected call of Present Proof Protocol."); }
  //       }).catch((e: any) => {

  //         console.warn("Rejected: " + e);
  //       });
  //     }
  //   });
  //   this.appUrl = this.envService.env['VCISS_URL'] + "/verify" +
  //     "?_oobid=" + this.presentationID +
  //     "&credentialProviderLink=" + this.envService.env['CS_URL'] + "/validation";
  //   this.showQrCode = true;
  //   this.isRequestPresentation = true;
  // }

  // requestPresentation() {
  //   this.showQrCode = true;
  //   this.localDataStorageService.setData("credentialArray", [this.requiredPerson, this.requiredAddress, this.requiredAgeProof]); // credential to be requested
  //   //this.genPPPMessage();
  // }

  processReceivedVC(credential: VerifiableCredential1xModel) {
    let vcType = this.evaluateForm(credential)
    this.credSubject.creditInstitution = this.sepaForm.controls['creditInstitution'].value;
    this.credSubject.iban = this.sepaForm.controls['iban'].value;
    this.credSubject.bic = this.sepaForm.controls['bic'].value;
    if (vcType.length > 0) {
      let evidence: EvidenceModel = {
        docId: (' ' + credential.id).slice(1),
        documentPresence: 'Digital',
        evidenceDocument: vcType,
        id: '__HOME_URL__/Verification/' + crypto.randomUUID(),
        subjectPresence: 'Physical',
        type: ['DocumentVerification'],
        verifier: 'https://demo.shop.it.kaprion.net/samples/KAPRION/SysActor/8506cbb4-c8d0-4b34-8f93-c5638893f0e2'
      };
      this.proofArray.push(vcType);
      this.evidence.push(evidence);
    } else {
      const langPipe = new GetLangPipe();
      let requestedItem = langPipe.transform(vcType);
      this.alertMessage = "Typ des Nachweises nicht gültig. Vorgang wird abgebrochen." + " Typ: " + vcType;
      this.hintMessage = "Der Nachweis " + requestedItem + " ist fehlerhaft. Bitte lassen Sie sich " + requestedItem + "erneut ausstellen."
      let dialogList = document.getElementById("dialog-box-alert");
      (dialogList as any).showModal();
    }
  }

  evaluateForm(cred: VerifiableCredential1xModel): string {
    if (cred.type.includes('KDKPersonK')) {
      //let subject = cred.credentialSubject as KdkBiometricPhotoKModel;
      return "KDKPersonK"
    } else if (cred.type.includes("KDKAgeProofK")) {
      return "KDKAgeProof"
    } else if (cred.type.includes("KDKAddressK")) {
      return "KDKAddressK"
    } else {
      return ""
    }
  }

  displaySEPA() {
    this.isInput = false;
    this.isDisplaySepa = true;
    this.showQrCode = false;
    this.state = StateMachine.REQ_PRESENTATION;
    this.isRequestPresentation = true;
    // this.sepaForm.controls['creditInstitution'].disable()
    // this.sepaForm.controls['iban'].disable()
    // this.sepaForm.controls['bic'].disable()
    // this.sepaForm.controls['repeatablePayment'].disable()
    // this.sepaForm.controls['intendedUse'].disable()
    this.sepaForm.disable()
  }

  onFinalSubmit() {
    if (this.state === StateMachine.INPUT_SEPA) {
      this.displaySEPA()
    } else {
      this.isDisplaySepa = false;
      this.isRequestPresentation = false;
      this.issueSepa()
    }
  }

  expandRequestPresentationByPurpose() {
    this.requestPresentation.forEach((cType: string, index: number) => {
      let typeString: string = "";
      if (cType.includes("BADA")) {typeString = "Bankinformationen "}
      else if (cType.includes("KDK")) {typeString = "persönlichen Informationen "}
      let purposeString: string = 'Ihre ' + typeString + 'werden für die Ausstellung eines SEPA-Lastschriftmandats benötigt';
      let obj = {
        [cType]: {
          'purpose': purposeString
        }
      }
      this.purposeObjectArray.push(obj);
      this.localDataStorageService.setData("purposeObjectArray", this.purposeObjectArray)
    })
  }

  private issueSepa() {

    this.credSubject.creditInstitution = this.sepaForm.controls['creditInstitution'].value;
    this.credSubject.iban = this.sepaForm.controls['iban'].value;
    this.credSubject.bic = this.sepaForm.controls['bic'].value;
    this.credSubject.repeatablePayment = this.sepaForm.controls['repeatablePayment'].value;
    this.credSubject.intendedUse = this.sepaForm.controls['intendedUse'].value;

    let credentialContext = this.envService.env['CS_URL'] + "/credentialSubject/v6";
    let id = this.envService.env['HOME_URL'] + "/samples/KAPRION/SEPADirectDebitMandate/" + crypto.randomUUID();

    let credential = this.csb
      .id(id)
      .addContext(credentialContext)
      .addType("SEPADirectDebitMandate")
      .issuanceDate()
      .evidence(this.evidence)
      .expirationDate(this.csb.getDateTime(true, 1))
      .credentialSubject(this.credSubject)
      .build()

    // send VC

    this.localDataStorageService.setData("credentialArray", [credential]);
    this.localDataStorageService.setData("ticketIdArray", [id]);
    this.localDataStorageService.setData("taskType", "cs.icp.split.offerCredential");
    this.localDataStorageService.setData("requestPresentation", this.requestPresentation);
    this.expandRequestPresentationByPurpose();

    let message = this.createMessageService.prepareMessageV3(Issuer.getIDIBankIssuer(), true, true, false).then((msg: any) => {
      this.showQrCode = true;
      this.appUrl = this.envService.env['VCISS_URL'] + "/issue" +
        "?_oobid=" + this.localDataStorageService.getData("oobid") +
        "&credentialProviderLink=" + this.envService.env['VCISS_URL'] + "/issuance";
      if (msg instanceof MessageContainer) {
        if (msg.getMessages().length < msg.getTaskAmount()) {
          msg.setTaskAmount(msg.getMessages().length);
        }
        msg = JSON.stringify(msg, (key, value) => {
          if (value === undefined || value === null) {
            return undefined;
          }
          // Filtering null and undefined values from arrays
          if (Array.isArray(value)) {
            return value.filter((item) => item !== null && item !== undefined);
          }
          return value;
        });

        this.dataService.send(msg, this.envService.env['VCISS_URL'] + '/initSession', true).then((response: any) => {
          if (response['type'] === "de.kaprion.icp.s2p.issueCredential.req") {
            this.showQrCode = false;
            this.isSEPA = true;
            //this.messageText = "Ihr SEPA-Lastschriftmandat wird nun ausgestellt."
            //this.messageArray.push(this.messageText);
            let attach: any[] = []
            if (response['credentialApplication'] !== undefined) {
              if (response['credentialApplication']['verifiableCredential'] !== undefined) {
                attach = response['credentialApplication']['verifiableCredential'];
                attach.forEach((credential: any) => {
                  this.requestPresentation.forEach(requested => {
                    if (credential.type.includes(requested)) {
                      this.processReceivedVC(<VerifiableCredential1xModel>credential);
                    }
                  })
                });
                if (this.proofArray.length === this.requestPresentation.length) {
                  this.isIssuance = true;
                  this.statusOk = true;
                  //this.messageText = "Ihr Konto wird nun eröffnet.";
                  let finalCredential = this.csb
                    .id(id)
                    .addContext(credentialContext)
                    .addType("SEPADirectDebitMandate")
                    .issuanceDate()
                    .expirationDate(this.csb.getDateTime(true, 1))
                    .credentialSubject(this.credSubject)
                    .evidence(this.evidence)
                    .build();
                  this.localDataStorageService.setData("credentialArray", [finalCredential]);
                  this.localDataStorageService.setData("taskType", "cs.icp.split.issueCredential");
                  let finalMessage = this.createMessageService.prepareMessageV3(Issuer.getLHDIssuer(), true, true, false).then(finalMsg => {
                    if (finalMsg instanceof MessageContainer) {
                      finalMsg = JSON.stringify(finalMsg, (key, value) => {
                        if (value === undefined || value === null) {
                          return undefined;
                        }
                        // Filtering null and undefined values from arrays
                        if (Array.isArray(value)) {
                          return value.filter((item) => item !== null && item !== undefined);
                        }
                        return value;
                      });
                      this.dataService.send(finalMsg, this.envService.env['VCISS_URL'] + '/initSession').then((response: any) => {
                        this.messageText = "Ihr Nachweis wurde erfolgreich ausgestellt.";
                        this.messageArray.push(this.messageText);
                        if (response['ok']) {
                          this.statusOk = true;
                          this.isIssuance = false;
                        } else {
                          this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['status'];
                          this.messageArray.push(this.messageText);
                          this.statusOk = false;
                        }
                      });
                    }
                  })
                } else {
                  this.alertMessage = "Vorgelegte Nachweise erfüllen nicht die Anforderung zur Ausstellung des VDV-Tickets."
                  this.hintMessage = "Folgende Nachweise erfüllen nicht die Anforderungen: " + this.checkEntry();
                  let dialogList = document.getElementById("dialog-box-alert");
                  (dialogList as any).showModal();
                  this.statusOk = false;
                }
              } else {
                this.statusOk = false;
                this.messageText = "Es wurden keine Nachweise präsentiert. Folgende Nachweise müssen präsentiert werden: "
                this.messageArray.push(this.messageText);
                this.requestPresentation.forEach(requestedPresentation => {
                  this.messageText += requestedPresentation + "\n"
                });
              }
            } else {
              this.statusOk = false;
              this.messageText = "Es wurden keine Nachweise präsentiert. Folgende Nachweise müssen präsentiert werden: "
              this.messageArray.push(this.messageText);
              this.requestPresentation.forEach(requestedPresentation => {
                this.messageText += requestedPresentation + "\n"
              });
            }


          } else {
            this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['status'];
            this.messageArray.push(this.messageText);
            this.statusOk = false;
          }
          // setTimeout(() => {
          //   this.messageText = "";
          //   this.router.navigate(["ticketshop"])
          // }, 5000)
          this.credentialArray = [];
          this.reset();
        }).catch((e) => {

          console.warn("Rejected: " + e);
        });
      }
    });
  }

  checkEntry() {
    return this.requestPresentation.filter(entry => !this.proofArray.includes(entry));
  }

  reset() {
    this.proofArray = [];
    this.sessionId = this.localDataStorageService.getData("sessionId");
    this.buttonText = "Prozess starten";
    this.sepaForm.controls['creditInstitution'].setValue("");
    this.sepaForm.controls['givenName'].setValue("");
    this.sepaForm.controls['familyName'].setValue("");
    this.sepaForm.controls['iban'].setValue("");
    this.sepaForm.controls['bic'].setValue("");
    this.sepaForm.controls['repeatablePayment'].setValue("false");
    this.sepaForm.controls['intendedUse'].setValue("");
    this.sepaForm.controls['creditInstitution'].enable();
    this.sepaForm.controls['givenName'].enable();
    this.sepaForm.controls['familyName'].enable();
    this.sepaForm.controls['iban'].enable();
    this.sepaForm.controls['bic'].enable();
    this.sepaForm.controls['repeatablePayment'].enable();
    this.sepaForm.controls['intendedUse'].enable();
    this.isDisplayAddress = false;
    this.isDisplayAgeProof = false;
    this.isDisplayPerson = false;
    this.isDisplaySepa = false;
    this.isInput = true;
    this.credentialSubjects = [];
    this.credentialArray = [];
    this.presentationID = "";
    this.state = StateMachine.REQ_PRESENTATION;
    this.appUrl = "";
    this.showQrCode = false;
    try {
      const pic = document.querySelector('#pic');
      if (pic !== null) pic.remove();
    } catch (e) { }
    try {
      const table = document.querySelector('#table');
      if (table !== null) table.remove();
    } catch (e) { }
  }

  //abort-functionality | back-button
  onClickBack() {
    this.isDisplaySepa = !this.isDisplaySepa;
    this.isInput = true;
    this.sepaForm.controls['creditInstitution'].enable();
    this.sepaForm.controls['iban'].enable();
    this.sepaForm.controls['bic'].enable();
    this.sepaForm.controls['repeatablePayment'].enable();
    this.sepaForm.controls['intendedUse'].enable();
    this.buttonText = "Eingaben prüfen";
    this.state = StateMachine.REQ_PRESENTATION;
  }

  onClickCancel() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).showModal();
  }

  fillForm() {
    this.sepaForm.controls['creditInstitution'].setValue("IDI Bank");
    this.sepaForm.controls['iban'].setValue(this.ibanGenService.generateIban());
    this.sepaForm.controls['bic'].setValue("IDI80376");
    this.sepaForm.controls['repeatablePayment'].setValue(false);
    this.sepaForm.controls['intendedUse'].setValue("Einzelfahrt Zahlung");

  }

  // request confirmation
  onDialogCancel() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).close();
  }

  onDialogOk() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).close();
    this.localDataStorageService.setData("navigationalFragment", "");
    this.router.navigate(["home"]);
  }

  onDialogIbanOk() {
    let dialogList = document.getElementById("dialog-box-iban");
    (dialogList as any).close();
  }

  onDialogBack() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[1] as any).close();
    this.state = StateMachine.REQ_PRESENTATION;
    this.sessionId = this.localDataStorageService.getData("sessionId");
    this.buttonText = "Eingaben prüfen";
    this.sepaForm.controls['creditInstitution'].enable();
    this.sepaForm.controls['iban'].enable();
    this.sepaForm.controls['bic'].enable();
    this.sepaForm.controls['repeatablePayment'].enable();
    this.sepaForm.controls['intendedUse'].enable();
    this.isDisplayAddress = false;
    this.isDisplayAgeProof = false;
    this.isDisplayPerson = false;
    this.isDisplaySepa = false;
    this.isInput = true;
    this.credentialSubjects = [];
    this.credentialArray = [];
    this.presentationID = "";
    this.appUrl = "";
    this.showQrCode = false;
  }

}

enum StateMachine {
  REQ_PRESENTATION,
  INPUT_SEPA,
  ISSUE_SEPA
}

