import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UniqueIdentifiersService {

  constructor() { }

  get uuidV4() {
    return crypto.randomUUID();
  }
}
