import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EnvService, environment } from '../environments/environment';
import { LocalDataStorageService } from './services/local-data-storage.service';
import { UniqueIdentifiersService } from './services/unique-identifiers.service';
import { WebsocketService } from './services/websocket.service';
import { UseCaseModel } from './models/kaprion/use-case.model';
import { Environment } from '../environments/environment';

declare let env: Environment
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'demo-app';
  imgURL = environment.imgURL;
  currentRouteHeader = 'KAPRION';
  currentHeaderMenuIcon = {
    'img': 'menu-icon.png',
    'icon_class': 'menu-icon',
    'menu_class': 'menu-popup-kaprion'
  };
  idiLogo = 'PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMzgiIGhlaWdodD0iODYiPjxnIGZpbGw9IiNmZmYiPjxwYXRoIGQ9Ik0zLjg3NCA0MC4zMjZxLTEuMDE1IDAtMS43My0uNzE0LS43MTQtLjcxNS0uNzE0LTEuNzNWMjQuODNxMC0xLjAyNi43MTQtMS43NDguNzE1LS43MjIgMS43My0uNzIyaDE3LjIxMnExLjAyNiAwIDEuNzQ4LjcyMnQuNzIyIDEuNzQ4djEzLjA1MnEwIDEuMDE1LS43MjIgMS43My0uNzIyLjcxNC0xLjc0OC43MTRIMy44NzR6bTAtOS41OTRoMTcuMjEydi0zLjM1NEgzLjg3NHYzLjM1NHoiLz48dGV4dCB4PSIyNSIgeT0iNDAiIGZvbnQtZmFtaWx5PSJCcnVzaCBTY3JpcHQgTVQsR2FyYW1vbmQsR2VvcmdpYSxUaW1lcyBOZXcgUm9tYW4iIGZvbnQtc2l6ZT0iMS42ZW0iPklESSBCYW5rPC90ZXh0PjxwYXRoIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9Ii41IiBkPSJNNSA0NGgxMjAiLz48L2c+PC9zdmc+';

  isShowMenu: boolean = false;
  selectedMenuIndex: number = 0;
  isLinkToAssets: boolean = false;
  appInstanceOobid: string = "";
  environment: string = "";
  timestamp: string = "";
  hasSCC: boolean = true;

  /**
   * List of Uses Cases used for rendering the menu
   * @param id the index of this array
   * @param title the name of the menu entry/site mane
   * @param path the route path
   */
  useCases: UseCaseModel[] = [
    { id: 0, title: "Hauptseite", path: "/home" },
    { id: 1, title: "Kommunale Datenkarte (KDK)", path: "/credential" },
    { id: 2, title: "Wohngeldbescheid", path: "/wohngeldbescheid" },
    { id: 3, title: "Eröffnung eines Bankkontos", path: "/eubankaccount"},
    { id: 4, title: "Bank-Login", path: "/eubanklogin" },
    { id: 5, title: "Erteilung des SEPA-Lastschriftmandats", path: "/sepamandate" },
    { id: 6, title: "Sozialpass", path: "/ddp" },
    { id: 7, title: "Ticketverkauf", path: "/ticketshop" },
    //{ id: 7, title: "SCC2023 Besuchernachweis Ausstellung", path: "/party-selector" },
    //{ id: 8, title: "SCC2023 Besuchernachweis Validierung", path: "/sccv" },
    { id: 9, title: "Privacy Policy", path: "/pp" },
    { id: 10, title: "Terms & Conditions", path: "/tc" }
  ];

  constructor(private router: Router,
    private websocketService: WebsocketService,
    private uniqueIdentService: UniqueIdentifiersService,
    private localDataStorageService: LocalDataStorageService,
    private envService: EnvService) {
    this.router.events.subscribe((route: any) => {
      if (route['url'] !== undefined) {
        let filteredIndexes = this.useCases.filter((x: any) => x['path'] === route['url']);
        if (filteredIndexes.length > 0) {
          this.selectedMenuIndex = filteredIndexes[0]['id'];
        }
        this.localDataStorageService.setData("oobid", "");
        if (route['url'] === '/credential' || route['url'] === '/wohngeldbescheid' || route['url'] === '/ddp') {
          this.currentRouteHeader = 'DRESDEN';
          this.currentHeaderMenuIcon = {
            'img': 'menu_icon_white.png',
            'icon_class': 'menu-icon-white',
            'menu_class': 'menu-popup-dresden'
          };
        } else if (route['url'] === '/ticketshop' || route['url'].indexOf('/ticketselection/') > -1) {
          this.currentRouteHeader = 'DVB';
          this.currentHeaderMenuIcon = {
            'img': 'menu-icon.png',
            'icon_class': 'menu-icon',
            'menu_class': 'menu-popup-dvb'
          };
        } else if (route['url'] === '/sepamandate' || route['url'] === '/eubankaccount' || route['url'] === '/eubanklogin') {
          this.currentRouteHeader = 'IDI';
          this.currentHeaderMenuIcon = {
            'img': 'menu_icon_white.png',
            'icon_class': 'menu-icon-white',
            'menu_class': 'menu-popup-idi'
          };
        } else {
          this.currentRouteHeader = 'KAPRION';
          this.currentHeaderMenuIcon = {
            'img': 'menu-icon.png',
            'icon_class': 'menu-icon',
            'menu_class': 'menu-popup-kaprion'
          };
        }
      }
    });
  }

  ngOnInit() {
    this.appInstanceOobid = crypto.randomUUID();
    this.localDataStorageService.setData("sessionId", this.appInstanceOobid);
    //this.websocketService.connect();

    //send the oobid of the passed credential to the php-script
    let message = { sessionId: this.appInstanceOobid };
    //TODO rework in the future, to have it more dynamically and only need to package component once
    this.environment = this.envService.env['bannerText'];
    this.timestamp = environment.timestamp;
    this.localDataStorageService.setData("additionalFavorite", -1)
  }

  // to show the menu
  showMenu() {
    this.isShowMenu = true;
  }

  // to hide the menu
  hideMenu() {
    this.isShowMenu = false;
  }

  // to navigate on menu selection
  menuSelect(index: number) {
    this.selectedMenuIndex = index;

    if (index < this.useCases.length) {
      this.router.navigate([this.useCases[index].path]);
    }
    else {
      this.router.navigate([this.useCases[0].path]);
    }

    this.isShowMenu = false;
  }

  onSubmitCredentials() {
    this.router.navigate(['/qrcode/']);
  }
}
