<header class="heading">Kommunale Datenkarte (KDK)</header>

<div *ngIf="!showQRCode" class="main-wrapper container-fluid">
  <div *ngIf="hasSelectedCredentialsToAdd" class="inside">

    <!--
      PROGRESS BAR START
    -->
    <app-progress-bar [hasDresdenPass]="hasDresdenPass" [hasSepa]="hasSepa" [step]="step"></app-progress-bar>
    <!--
      PROGRESS BAR END
    -->

    <!--
      PERSON FORM START
    -->
    <form (ngSubmit)="onSubmitProfile()" *ngIf="step === 1" [formGroup]="profileForm"
      class="form-content was-validated">

      <br>

      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
        <div class="card border-0 card_shadow">
          <div class="card-body">

            <div class="row mx-auto mb-3">
              <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="honorific-prefix ">Anrede</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <select formControlName="honorificPrefix" id="honorific-prefix" class="form-select">
                      <option value="Mr.">Herr</option>
                      <option value="Mrs.">Frau</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mx-auto mb-3">
              <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="first-name" class="">Vorname</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <input formControlName="givenName" id="first-name" type="text" class="form-control" required>
                    <div
                      *ngIf="profileForm.controls['givenName'].errors?.['required'] && profileForm.controls['givenName'].touched"
                      class="error">
                      Vorname ist ein Pflichtfeld.
                    </div>
                    <div
                      *ngIf="profileForm.controls['givenName'].errors?.['minlength'] && profileForm.controls['givenName'].touched"
                      class="error">
                      Mindestens zwei Zeichen erforderlich.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mx-auto mb-3">
              <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="last-name">Nachname</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <input formControlName="familyName" id="last-name" type="text" class="form-control" required>
                    <div
                      *ngIf="profileForm.controls['familyName'].errors?.['required'] && profileForm.controls['familyName'].touched"
                      class="error">
                      Nachname ist ein Pflichtfeld.
                    </div>
                    <div
                      *ngIf="profileForm.controls['familyName'].errors?.['minlength'] && profileForm.controls['familyName'].touched"
                      class="error">
                      Mindestens zwei Zeichen erforderlich.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mx-auto mb-3">
              <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="birth-name">Geburtsname</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <input formControlName="birthName" id="birth-name" type="text" class="form-control" required>
                    <div
                      *ngIf="profileForm.controls['birthName'].errors?.['required'] && profileForm.controls['birthName'].touched"
                      class="error">
                      Geburtsname ist ein Pflichtfeld.
                    </div>
                    <div
                      *ngIf="profileForm.controls['birthName'].errors?.['minlength'] && profileForm.controls['birthName'].touched"
                      class="error">
                      Mindestens zwei Zeichen erforderlich.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p class="flex-wrapper">Bitte füllen Sie die Pflichtfelder aus, um fortzufahren.</p>

          </div>
        </div>
      </div>

      <!--  <div class="element">
      <div class="col-elementLabel">
          <div class="elementValue">
              <label for="sepa">Sepa-Lastschrift-Mandat erteilen</label>
          </div>
      </div>
      <div class="col-elementValue">
          <div class="elementValue">
              <input id="sepa" type="checkbox" formControlName="sepa" (change)="onChange('sepa')">
          </div>
      </div>
      </div>

      <div class="element">
      <div class="col-elementLabel">
          <div class="elementValue">
              <label for="dresden-pass">Dresdenpass vorhanden</label>
          </div>
      </div>
      <div class="col-elementValue">
          <div class="elementValue">
              <input id="dresden-pass" type="checkbox" formControlName="dresdenPass" (change)="onChange('dresdenpass')">
          </div>
      </div>
      </div> -->


      <div class="flex-wrapper">
        <ng-container *ngIf="!isEdit">
          <button [disabled]="!profileForm.valid" class="link-box-white" type="submit">Weiter</button>
          <button (click)="fillProfile()" class="link-box-white" type="button">Form füllen</button>
          <button (click)="onClickBack()" class="link-box-white">Abbrechen</button>

        </ng-container>
        <ng-container *ngIf="isEdit">
          <button (click)="updateForm(profileForm, 'profileForm')" [disabled]="!profileForm.valid"
            class="link-box-white" name="next">Weiter
          </button>
          <button (click)="updateForm(profileForm, 'profileForm', 6)" [disabled]="!profileForm.valid"
            class="link-box-white" name="final">Bearbeiten beenden
          </button>
        </ng-container>
      </div>


    </form>

    <!--
      PERSON FORM END
    -->

    <!--
      ADDRESS FORM START
    -->
    <form (ngSubmit)="onSubmitAddress()" *ngIf="step === 2" [formGroup]="addressForm"
      class="form-content was-validated">

      <br>
      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
        <div class="card border-0 card_shadow">
          <div class="card-body">

            <div class="row mx-auto mb-3">
              <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="street-name">Straßenname </label>
                  </div>
                </div>
              </div>

              <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <input formControlName="streetName" id="street-name" type="text" class="form-control">
                    <div
                      *ngIf="addressForm.controls['streetName'].errors?.['required'] && addressForm.controls['streetName'].touched"
                      class="error">
                      Straßenname ist ein Pflichtfeld.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mx-auto mb-3">

              <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="house-number">Hausnummer</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <input formControlName="houseNumber" id="house-number" type="text" class="form-control" required>
                    <div
                      *ngIf="addressForm.controls['houseNumber'].errors?.['required'] && addressForm.controls['houseNumber'].touched"
                      class="error">
                      Hausnummer ist ein Pflichtfeld.
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="row mx-auto mb-3">
              <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="address-locality">Ort</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <input formControlName="addressLocality" id="address-locality" type="text" class="form-control"
                      required>
                    <div
                      *ngIf="addressForm.controls['addressLocality'].errors?.['required'] && addressForm.controls['addressLocality'].touched"
                      class="error">
                      Ort ist ein Pflichtfeld.
                    </div>
                    <div
                      *ngIf="addressForm.controls['addressLocality'].errors?.['pattern'] && addressForm.controls['addressLocality'].touched"
                      class="error">
                      Eingabe entspricht nicht der Formatvorgabe: 5 Ziffern <br>z. B.<b>01234</b>.
                    </div>
                  </div>
                </div>

              </div>
            </div>


            <div class="row mx-auto mb-3">
              <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="zip">Postleitzahl</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <input formControlName="postalCode" id="zip" type="text" class="form-control" pattern="[0-9]{5}"
                      required>
                    <div
                      *ngIf="addressForm.controls['postalCode'].errors?.['required'] && addressForm.controls['postalCode'].touched"
                      class="error">
                      Postleitzahl ist ein Pflichtfeld.
                    </div>
                    <div
                      *ngIf="addressForm.controls['postalCode'].errors?.['pattern'] && addressForm.controls['postalCode'].touched"
                      class="error">
                      Eingabe entspricht nicht der Formatvorgabe: 5 Ziffern <br>z. B.<b>01234</b>.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mx-auto mb-3">
              <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="resident-since">wohnhaft seit</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <input formControlName="residentSince" id="resident-since" type="date" class="form-control"
                      required>
                    <div
                      *ngIf="addressForm.controls['residentSince'].errors?.['required'] && addressForm.controls['residentSince'].touched"
                      class="error">
                      wohnhaft seit: ist ein Pflichtfeld.
                    </div>
                    <div
                      *ngIf="addressForm.controls['residentSince'].errors?.['pattern'] && addressForm.controls['residentSince'].touched"
                      class="error">
                      Eingabe entspricht nicht der Formatvorgabe: Datum <br>z. B.<b>01.01.2000</b> oder
                      <b>01-01-2000</b>.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p class="flex-wrapper">Bitte füllen Sie die Pflichtfelder aus, um fortzufahren.</p>

          </div>
        </div>
      </div>


      <div class="flex-wrapper">
        <ng-container *ngIf="!isEdit">
          <button [disabled]="!addressForm.valid" class="link-box-white" type="submit">Weiter</button>
          <button (click)="fillAddress()" class="link-box-white" type="button">Form füllen</button>
          <button (click)="onClickBack()" class="link-box-white">Abbrechen</button>
        </ng-container>
        <ng-container *ngIf="isEdit">
          <button (click)="updateForm(addressForm, 'addressForm')" [disabled]="!addressForm.valid" class="link-box"
            name="next">Weiter
          </button>
          <button (click)="updateForm(addressForm, 'addressForm', 6)" [disabled]="!addressForm.valid" class="link-box"
            name="final">Bearbeiten beenden
          </button>
        </ng-container>
      </div>

    </form>
    <!--
      ADDRESS FORM END
    -->

    <!--
      BIRTH CERTIFICATE FORM START
    -->
    <form (ngSubmit)="onSubmitBirthCertificate()" *ngIf="step === 3" [formGroup]="birthCertificateForm"
      class="form-content was-validated">

      <br>

      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
        <div class="card border-0 card_shadow">
          <div class="card-body">

            <div class="row mx-auto mb-3">
              <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="dob">Geburtsdatum</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <input formControlName="birthDate" id="dob" type="date" class="form-control" required>
                    <div
                      *ngIf="birthCertificateForm.controls['birthDate'].errors?.['required'] && birthCertificateForm.controls['birthDate'].touched"
                      class="error">
                      Geburtsdatum ist ein Pflichtfeld.
                    </div>
                    <div
                      *ngIf="birthCertificateForm.controls['birthDate'].errors?.['pattern'] && birthCertificateForm.controls['birthDate'].touched"
                      class="error">
                      Eingabe entspricht nicht der Formatvorgabe: Datum <br>z. B.<b>01.01.2000</b> oder
                      <b>01-01-2000</b>.
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="row mx-auto mb-3">
              <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="pob">Geburtsort</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <input formControlName="birthPlace" id="pob" type="text" class="form-control" required>
                    <div
                      *ngIf="birthCertificateForm.controls['birthPlace'].errors?.['required'] && birthCertificateForm.controls['birthPlace'].touched"
                      class="error">
                      Geburtsort ist ein Pflichtfeld.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mx-auto mb-3">
              <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="gender">Geschlecht</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <select formControlName="gender" id="gender" class="form-select" required>
                      <option value="Female">weiblich</option>
                      <option value="Male">männlich</option>
                      <option value="Divers">divers</option>
                    </select>
                    <div
                      *ngIf="birthCertificateForm.controls['gender'].errors?.['required'] && birthCertificateForm.controls['gender'].touched"
                      class="error">
                      Geschlecht ist ein Pflichtfeld.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div formGroupName="parent">

              <div class="row mx-auto mb-3">
                <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                  <div class="col-elementLabel">
                    <div class="elementValue">
                      <label for="parent"><b>Eltern</b></label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="sub-element">

                <div class="row mx-auto mb-3">
                  <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                    <div class="col-elementLabel">
                      <div class="elementValue">
                        <label for="first-name">Vorname</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <div class="col-elementValue">
                      <div class="elementValue">
                        <input formControlName="givenName" id="first-name" type="text" class="form-control" required>
                        <div
                          *ngIf="parent.controls['givenName'].errors?.['required'] && parent.controls['givenName'].touched"
                          class="error">
                          Vorname ist ein Pflichtfeld.
                        </div>
                        <div
                          *ngIf="parent.controls['givenName'].errors?.['minlength'] && parent.controls['givenName'].touched"
                          class="error">
                          Mindestens zwei Zeichen erforderlich.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mx-auto mb-3">
                  <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                    <div class="col-elementLabel">
                      <div class="elementValue">
                        <label for="last-name">Nachname</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <div class="col-elementValue">
                      <div class="elementValue">
                        <input formControlName="familyName" id="last-name" type="text" class="form-control" required>
                        <div
                          *ngIf="parent.controls['familyName'].errors?.['required'] && parent.controls['familyName'].touched"
                          class="error">
                          Nachname ist ein Pflichtfeld.
                        </div>
                        <div
                          *ngIf="parent.controls['familyName'].errors?.['minlength'] && parent.controls['familyName'].touched"
                          class="error">
                          Mindestens zwei Zeichen erforderlich.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="row mx-auto mb-3">
                  <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                    <div class="col-elementLabel">
                      <div class="elementValue">
                        <label for="gender">Geschlecht</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <div class="col-elementValue">
                      <div class="elementValue">
                        <select formControlName="gender" id="gender" class="form-select" required>
                          <option value="Female">weiblich</option>
                          <option value="Male">männlich</option>
                          <option value="Divers">divers</option>
                        </select>
                        <div *ngIf="parent.controls['gender'].errors?.['required'] && parent.controls['gender'].touched"
                          class="error">
                          Geschlecht ist ein Pflichtfeld.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mx-auto mb-3">
                  <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                    <div class="col-elementLabel">
                      <div class="elementValue">
                        <label for="same-as">URI des Eltern-Credentials</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <div class="col-elementValue">
                      <div class="elementValue">
                        <input formControlName="sameAs" id="same-as" type="text" class="form-control" required>
                        <div *ngIf="parent.controls['sameAs'].errors?.['required'] && parent.controls['sameAs'].touched"
                          class="error">
                          URI des Eltern-Credentials ist ein Pflichtfeld.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div formGroupName="parent1">
              <div class="sub-element">

                <div class="row mx-auto mb-3">
                  <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                    <div class="col-elementLabel">
                      <div class="elementValue">
                        <label for="first-name">Vorname</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <div class="col-elementValue">
                      <div class="elementValue">
                        <input formControlName="givenName" id="first-name" type="text" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mx-auto mb-3">
                  <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                    <div class="col-elementLabel">
                      <div class="elementValue">
                        <label for="last-name">Nachname</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <div class="col-elementValue">
                      <div class="elementValue">
                        <input formControlName="familyName" id="last-name" type="text" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mx-auto mb-3">
                  <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                    <div class="col-elementLabel">
                      <div class="elementValue">
                        <label for="gender">Geschlecht</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <div class="col-elementValue">
                      <div class="elementValue">
                        <select formControlName="gender" id="gender" class="form-select">
                          <option value="Female">weiblich</option>
                          <option value="Male">männlich</option>
                          <option value="Divers">divers</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mx-auto mb-3">
                  <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                    <div class="col-elementLabel">
                      <div class="elementValue">
                        <label for="same-as">URI des Eltern-Credentials</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-8 col-md-8 col-sm-8">
                    <div class="col-elementValue">
                      <div class="elementValue">
                        <input formControlName="sameAs" id="same-as" type="text" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <p class="flex-wrapper">Bitte füllen Sie die Pflichtfelder aus, um fortzufahren.</p>

          </div>
        </div>
      </div>


      <div class="flex-wrapper">
        <ng-container *ngIf="!isEdit">
          <button [disabled]="!birthCertificateForm.valid" class="link-box-white" type="submit">Weiter</button>
          <button (click)="fillBirthCertificate()" class="link-box-white" type="button">Form füllen</button>
          <button (click)="onClickBack()" class="link-box-white">Abbrechen</button>
        </ng-container>
        <ng-container *ngIf="isEdit">
          <button (click)="updateForm(birthCertificateForm, 'birthCertificateForm')"
            [disabled]="!birthCertificateForm.valid" class="link-box" name="next">Weiter
          </button>
          <button (click)="updateForm(birthCertificateForm, 'birthCertificateForm', 6)"
            [disabled]="!birthCertificateForm.valid" class="link-box" name="final">Bearbeiten beenden
          </button>
        </ng-container>
      </div>

    </form>
    <!--
      BIRTH CERTIFICATE FORM END
    -->

    <!--
      AGE PROOF FORM START
    -->
    <form (ngSubmit)="onSubmitAgeProof()" *ngIf="step === 4" [formGroup]="ageProofForm"
      class="form-content was-validated">

      <br>
      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
        <div class="card border-0 card_shadow">
          <div class="card-body">

            <div class="row mx-auto mb-3">
              <div class="col-lg-5 col-md-5 col-md-5 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="term-code">Verweis auf Gesetzesgrundlage</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-7 col-md-7 col-sm-7">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <input formControlName="termCode" id="term-code" type="text" class="form-control" required>
                    <div
                      *ngIf="ageProofForm.controls['termCode'].errors?.['required'] && ageProofForm.controls['termCode'].touched"
                      class="error">
                      Verweis auf Gesetzesgrundlage ist ein Pflichtfeld.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mx-auto mb-3">
              <div class="col-lg-5 col-md-5 col-md-5 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="indefined-termset">Link zur Gesetzesgrundlage</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-7 col-md-7 col-sm-7">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <input formControlName="inDefinedTermSet" id="indefined-termset" type="text" class="form-control"
                      required>
                    <div
                      *ngIf="ageProofForm.controls['inDefinedTermSet'].errors?.['required'] && ageProofForm.controls['inDefinedTermSet'].touched"
                      class="error">
                      Link zur Gesetzesgrundlage ist ein Pflichtfeld.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mx-auto mb-3">
              <div class="col-lg-5 col-md-5 col-md-5 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="meets-requirement">Erfüllt Nachweis</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-7 col-md-7 col-sm-7">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <select formControlName="meetsRequirement" id="meets-requirement" class="form-select">
                      <option value="true">Ja</option>
                      <option value="true">Nein</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mx-auto mb-3">
              <div class="col-lg-5 col-md-5 col-md-5 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="keywords">International anwendbares Recht</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-7 col-md-7 col-sm-7">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <input formControlName="keywords" id="keywords" type="text" class="form-control" required>
                    <div
                      *ngIf="ageProofForm.controls['keywords'].errors?.['required'] && ageProofForm.controls['keywords'].touched"
                      class="error">
                      International anwendbares Recht ist ein Pflichtfeld.
                    </div>
                    <div
                      *ngIf="ageProofForm.controls['keywords'].errors?.['pattern'] && ageProofForm.controls['keywords'].touched"
                      class="error">
                      Eingabe entspricht nicht der Formatvorgabe:<br>Erlaubte Zeichen: Buchstaben, Ziffern und
                      "_"<br>Trennzeichen: Leerzeichen<br>z. B.<b>PEGI14</b> oder <b>USK</b>.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p class="flex-wrapper">Bitte füllen Sie die Pflichtfelder aus, um fortzufahren.</p>

          </div>
        </div>
      </div>

      <div class="flex-wrapper">
        <ng-container *ngIf="!isEdit">
          <button [disabled]="!ageProofForm.valid" class="link-box-white" type="submit">Weiter</button>
          <button (click)="fillAgeProof()" class="link-box-white" type="button">Form füllen</button>
          <button (click)="onClickBack()" class="link-box-white">Abbrechen</button>
        </ng-container>
        <ng-container *ngIf="isEdit">
          <button (click)="updateForm(ageProofForm, 'ageProofForm')" [disabled]="!ageProofForm.valid" class="link-box"
            name="next">Weiter
          </button>
          <button (click)="updateForm(ageProofForm, 'ageProofForm', 6)" [disabled]="!ageProofForm.valid"
            class="link-box" name="final">Bearbeiten beenden
          </button>
        </ng-container>
      </div>

    </form>
    <!--
      AGE PROOF FORM END
    -->

    <!--
      BIOMETRIC FOTO FORM START
    -->
    <form (ngSubmit)="onSubmitBiometricPhoto()" *ngIf="step === 5" [formGroup]="biometricPhotoForm"
      class="form-content was-validated">

      <br>

      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
        <div class="card border-0 card_shadow">
          <div class="card-body">

            <div class="row mx-auto mb-3">

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label for="biometric-photo">Bitte wählen Sie ein biometrisches Passbild für das Hochladen im
                      PNG-
                      oder JPG-Format </label>
                  </div>
                </div>

                <div class="col-elementValue">
                  <div class="elementValue">
                    <input (change)="onFileChange($event)" data-max-size="2048"
                      accept="image/webp, image/jpeg, image/png" formControlName="img" id="biometric-photo" type="file"
                      class="form-control" required>
                    <div
                      *ngIf="biometricPhotoForm.controls['img'].errors?.['required'] && biometricPhotoForm.controls['img'].touched"
                      class="error">
                      Bitte wählen Sie eine Datei aus.
                    </div>
                    <div *ngIf="isMaxSizeExceeded" class="error">Maximale Uploadgröße von 2 MB überschritten! Bild wird
                      komprimiert...</div>
                    <div *ngIf="isImageRedrawn" class="card-body">
                          <img [src]="image.src">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--<p class="flex-wrapper">Bitte wählen Sie eine Datei, um fortzufahren.</p>-->
          </div>
        </div>
      </div>
      <div class="flex-wrapper">
        <ng-container *ngIf="!isEdit">
          <button [disabled]="!biometricPhotoForm.valid" class="link-box-white" type="submit">Weiter</button>
          <button (click)="onClickBack()" class="link-box-white">Abbrechen</button>
          <!-- <button type="button" (click)="fillBiometricPhoto()">Form füllen</button> -->
        </ng-container>
        <ng-container *ngIf="isEdit">
          <button (click)="updateForm(biometricPhotoForm, 'biometricPhotoForm')" [disabled]="!biometricPhotoForm.valid"
            class="link-box-white" name="next">Weiter
          </button>
          <button (click)="updateForm(biometricPhotoForm, 'biometricPhotoForm', 6)"
            [disabled]="!biometricPhotoForm.valid" class="link-box-white" name="final">Bearbeiten beenden
          </button>
        </ng-container>
      </div>

    </form>
    <!--
      BIOMETRIC FOTO FORM END
    -->


    <!--  <form *ngIf="hasSepa && step === 6" [formGroup]="sepaForm" (ngSubmit)="onSubmitSepa()">

    <br>

    <div class="element">
        <div class="col-elementLabel">
            <div class="elementValue">
                <label for="credit-institution">Kreditinstitut</label>
            </div>
        </div>
        <div class="col-elementValue">
            <div class="elementValue">
                <input id="credit-institution" type="text" formControlName="creditInstitution">
            </div>
        </div>
    </div>

    <div class="element">
        <div class="col-elementLabel">
            <div class="elementValue">
                <label for="iban">IBAN</label>
            </div>
        </div>
        <div class="col-elementValue">
            <div class="elementValue">
                <input id="iban" type="text" formControlName="iban">
            </div>
        </div>
    </div>

    <div class="element">
        <div class="col-elementLabel">
            <div class="elementValue">
                <label for="bic">BIC</label>
            </div>
        </div>
        <div class="col-elementValue">
            <div class="elementValue">
                <input id="bic" type="text" formControlName="bic">
            </div>
        </div>
    </div>

    <div class="element">
        <div class="col-elementLabel">
            <div class="elementValue">
                <label for="repeatable-payment">Wiederkehrende Zahlungen</label>
            </div>
        </div>
        <div class="col-elementValue">
            <div class="elementValue">
                <input id="repeatable-payment" type="checkbox" formControlName="repeatablePayment">
            </div>
        </div>
    </div>

    <div class="element">
        <div class="col-elementLabel">
            <div class="elementValue">
                <label for="intended-use">Verwendung</label>
            </div>
        </div>
        <div class="col-elementValue">
            <div class="elementValue">
                <input id="intended-use" type="text" formControlName="intendedUse">
            </div>
        </div>
    </div>

    <p>Bitte füllen Sie die Pflichtfelder aus, um fortzufahren.</p>
    <button type="submit" [disabled]="!sepaForm.valid">Weiter</button>

</form>

<form *ngIf="hasDresdenPass && step === 7 || !hasSepa && hasDresdenPass && step === 6" [formGroup]="dresdenPassForm" (ngSubmit)="onSubmitDresdenPass()">

    <br>

    <div class="element">
        <div class="col-elementLabel">
            <div class="elementValue">
                <label for="id">ID</label>
            </div>
        </div>
        <div class="col-elementValue">
            <div class="elementValue">
                <input id="id" type="text" formControlName="id">
            </div>
        </div>
    </div>

    <p>Bitte füllen Sie die Pflichtfelder aus, um fortzufahren.</p>
    <button type="submit" [disabled]="!dresdenPassForm.valid">Weiter</button>

</form> -->

    <!--
      SUMMARY & SUBMIT FORM START
    -->
    <form
      *ngIf="(hasDresdenPass && hasSepa && step === 8) || ((!hasDresdenPass && hasSepa || hasDresdenPass && !hasSepa) && step === 7) || (!hasDresdenPass && !hasSepa && step === 6)"
      [formGroup]="finalForm">

      <br>
      <span class="flex-wrapper">Bitte prüfen Sie Ihre Eingaben</span>

      <div *ngFor="let cred of credentialArray">

        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-10 mx-auto p-3 pt-3">
          <div class="card border-0 card_shadow">
            <div class="card-body">

              <h4 class="flex-wrapper mb-2">{{cred['type'][1]| getLang}}</h4>

              <div *ngFor="let credentialSubjectProperty of cred['credentialSubject'] | keyvalue">
                <div class="element">
                  <div *ngIf="!isArray(credentialSubjectProperty)" class="col-elementLabel">
                    <div class="elementValue">
                      <b>{{credentialSubjectProperty.key | getLang}}</b>
                    </div>
                  </div>
                  <ng-container *ngIf="!isObject(credentialSubjectProperty.value)">
                    <div class="col-elementValue">
                      <ng-container *ngIf="credentialSubjectProperty.key === 'img'">
                        <img [src]="'data:image/webp;base64,' + credentialSubjectProperty.value | base64ImageDecode"
                          alt="biometrisches Photo" style="width:138px;height:177px">
                      </ng-container>
                      <ng-container *ngIf="credentialSubjectProperty.key !== 'img'">
                        {{credentialSubjectProperty.value | getLang}}
                      </ng-container>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isObject(credentialSubjectProperty.value)">
                    <ng-container *ngIf="isArray(credentialSubjectProperty.value)">
                      <ng-container *ngFor="let index of credentialSubjectProperty.value | hasType">
                        <ng-container *ngFor="let subProperty of getObjectValue(index) | keyvalue">
                          <ul>
                            <div class="element">
                              <div class="col-elementLabel">
                                <div class="elementValue">
                                  <b>{{subProperty.key | getLang}}</b>
                                </div>
                              </div>
                              <div class="col-elementValue">
                                <div class="elementValue">
                                  <ng-container *ngIf="subProperty.key === 'img'">
                                    <img [src]="'data:image/webp;base64,' + subProperty.value | base64ImageDecode"
                                      alt="biometrisches Photo" style="width:138px;height:177px">
                                  </ng-container>
                                  <ng-container *ngIf="subProperty.key !== 'img'">
                                    {{subProperty.value | getLang}}
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </ul>
                        </ng-container>
                      </ng-container>
                      <!-- <ul *ngFor="let entry of credentialSubjectProperty.value | hasType">
                          <div *ngFor="let entrySubElement of getObjectValue(entry) | keyvalue">
                              <div class="element">
                                  <div class="col-elementLabel">
                                      <div class="elementValue">
                                          <b>{{entrySubElement.key | getLang}}</b>
                                      </div>
                                  </div>
                                  <div class="col-elementValue">
                                      <div class="elementValue">
                                          <ng-container *ngIf="entrySubElement.key === 'image'">
                                              <img [src]="'data:image/png;base64,' + entrySubElement.value | base64ImageDecode"
                                                  style="width:138px;height:177px" alt="biometrisches Photo">
                                          </ng-container>
                                          <ng-container *ngIf="entrySubElement.key !== 'image'">
                                              {{entrySubElement.value}}
                                          </ng-container>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <li>{{entry}}</li>

                      </ul> -->
                    </ng-container>

                  </ng-container>
                  <ng-container
                    *ngIf="isObject(credentialSubjectProperty.value) && !isArray(credentialSubjectProperty.value)">
                    <div *ngFor="let subElement of getObjectValue(credentialSubjectProperty.value) | keyvalue">
                      <div class="element">
                        <div class="col-elementLabel">
                          <div class="elementValue">
                            <b>{{subElement.key | getLang}}</b>
                          </div>
                        </div>
                        <div class="col-elementValue">
                          <div class="elementValue">
                            <ng-container *ngIf="subElement.key === 'img'">
                              <img [src]="'data:image/webp;base64,' + subElement.value | base64ImageDecode"
                                alt="biometrisches Photo" style="width:138px;height:177px">
                            </ng-container>
                            <ng-container *ngIf="subElement.key !== 'img'">
                              {{subElement.value | getLang}}
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- <button type="submit" (click)="changeFormContent(cred['type'][1])">Eingaben korrigieren</button> -->

            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-10 mx-auto p-3 pt-3">
        <div class="card border-0 card_shadow">
          <div class="card-body">
            <div class="element">
              <div class="row mx-auto mb-3">
                <div class="spacer">
                  <div class="col-lg-12 col-md-12 col-sm-12 inline">
                    <div class="col-elementLabel">
                      <div class="elementValue negative-margin">
                        <label for="terms-and-aggreement">Bitte lesen und akzeptieren Sie unsere&nbsp;</label> <a
                          href="https://www.kaprion.de/policies/terms-of-service" target="_blank">AGB</a>
                      </div>
                    </div>
                    <div class="col-elementValue">
                      <div class="elementValue">
                        <input ngModel required formControlName="termsAndAgreement" id="terms-and-aggreement"
                          type="checkbox">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="flex-wrapper">
        <button (click)="onFinalSubmit()" [disabled]="!finalForm.valid" class="link-box-white" type="submit">Nachweise
          abholen</button>
        <button (click)="onClickBack()" class="link-box-white">Abbrechen</button>

      </div>


    </form>
    <!--
      SUMMARY & SUBMIT FORM END
    -->

  </div>
</div>
<dialog id="dialog-box">
  <h3>Möchten Sie die Eingabe Ihrer Daten beenden und zur Credentialauswahl zurückkehren?</h3>
  <div class="flex-wrapper">
    <button (click)="onDialogCancel()" class="link-box-white">Nein</button>
    <button (click)="onDialogOk()" class="link-box-white">Ja</button>
  </div>
</dialog>
<!-- Footer -->
<app-footer [footerButtons]="footerButtons" class="footer"></app-footer>
<app-showqrcode *ngIf="showQRCode" [verificationUrl]="appUrl" [isRequestPresentation]="false"></app-showqrcode>
<ng-container *ngFor="let message of messageArray">
  <app-toaster *ngIf="message.length > 0" [messageText]="message" [status]="statusOk"></app-toaster>
</ng-container>