<div class="main-wrapper container-fluid">
  <p *ngIf="!isMobile"><strong>Bitte scannen Sie den folgenden QR-Code mit Ihrer IDeal Wallet-App</strong></p>
  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 mx-auto p-3 pt-3 qr-code-wrapper">
    <!--<header *ngIf="!isMobile" class="showqrcode-heading">QR Code</header>-->
    <div class="card border-0 card_shadow">
      <div class="card-body text-center" [hidden]="isMobile">
        <canvas id="canvas"></canvas>
      </div>

      <!--<span class="showqrcode-text-1">Klicken Sie auf die folgende Schaltfläche, um die Wallet-App herunterzuladen:</span>-->
      <div *ngIf="isMobile && verificationUrl.length === 0" class="link-box">
        <a [href]="appUrl" rel="noreferrer" target="_blank">Nachweise mit der Wallet-App abholen</a>
      </div>
      <div *ngIf="isMobile && verificationUrl.length > 0" class="link-box">
        <a [href]="verificationUrl" rel="noreferrer" target="_blank">Nachweise mit der Wallet-App abholen</a>
      </div>
    </div>
  </div>
</div>