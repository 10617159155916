// component shows the footer buttons and action events of those buttons
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LocalDataStorageService } from 'src/app/services/local-data-storage.service';
import { UniqueIdentifiersService } from 'src/app/services/unique-identifiers.service';
import {VDVTicketModel} from '../../models/kaprion/vc/vdvticket.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  imgURL = environment.imgURL;

  @Input() footerButtons: any = [];
  @Input() selectedTicket: {[key: string]: any} = {};

  vdvticket: VDVTicketModel = {
    id: 'iss:REPLACE_ME_HOLDER',
    areaOfValidity: {
      provider: '',
      area: ['']
    },
    brand: '',
    category: '',
    class: '',
    passengerType: '',
    priceLevel: 0,
    ticketToken: '',
    totalPrice: {
      price: 0,
      priceCurrency: '',
      valueAddedTaxIncluded: false
    },
    vat: '',
    isProtectedByCRE: true
  }

  constructor(private location: Location, private router: Router, private localDataStorageService: LocalDataStorageService,
    private uniqueIdentService: UniqueIdentifiersService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['footerButtons'].currentValue !== undefined
      && changes['footerButtons'].currentValue !== null) {
      this.footerButtons = changes['footerButtons'].currentValue;
    }
  }

  /**
   * to handle the footer button click events
   * @param fb
   */
  footerButtonAction(fb: string) {
    if (fb === 'Jetzt kaufen') {
      // const _this = this;
      // var fallbackToStore = function () {
      //   _this.router.navigate(['/qrcode', 3]);
      // };
      // var openApp = function () {
      //   window.location.replace('https://mags.kt.et.kaprion.de/IDCardServiceProvider/IdealWalletTicketIssuanceEmulatorServlet?_oobid=cSERVER3-8940-TEST-8969-cOOBID946677&label=DVB+AG');
      // };
      // openApp();
      // setTimeout(fallbackToStore, 250);

      // this.router.navigate(['/qrcode', 3]);

      //window.location.replace('https://mags.kt.et.kaprion.de/IDCardServiceProvider/IdealWalletTicketIssuanceEmulatorServlet?_oobid=cSERVER3-8940-TEST-8969-cOOBID946677&label=DVB+AG');
      // if (this.selectedTicket['requiresCredential'] !== undefined && this.selectedTicket['requiresCredential']) {
      //   this.router.navigate(['/credential']);
      // } else {
      //   let ticketId = crypto.randomUUID();
      //   let ticketIdArray = [];
      //   ticketIdArray.push(ticketId);
      //   this.localDataStorageService.setData("ticketIdArray", ticketIdArray);
        // TODO
        // payment-step here
        // if (this.selectedTicket !== undefined) {
        //   console.log(this.selectedTicket)
        //   if (this.selectedTicket["requiresCredential"] === true) {
        //     this.localDataStorageService.setData("isVdvTicket", true);
        //     this.localDataStorageService.setData("preconditionals", ["KDKPersonK"]);
        //   }
        // }
        if (this.localDataStorageService.getData("sessionId").length === 0) {
          this.localDataStorageService.setData("sessionId", crypto.randomUUID());
        }
        if (this.localDataStorageService.getData("oobid").length === 0) {
          this.localDataStorageService.setData("oobid", crypto.randomUUID());
        }
        this.router.navigate(['/qrcode/' + this.localDataStorageService.getData("sessionId")]);
      //}
      //this.router.navigate(['/credential'])
    } else if (fb === 'Zurück') {
      this.location.back();
    } else if (fb === 'Tickets') {
      this.router.navigate(['/ticketselection', 3]);
    }
  }

}
