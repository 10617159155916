import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CredentialComponent } from './components/credential/credential.component';
import { HomeComponent } from './components/home/home.component';
import { ShowQrCodeComponent } from './components/showqrcode/showqrcode.component';
import { TicketBuyComponent } from './components/ticketbuy/ticketbuy.component';
import { TicketshopComponent } from './components/ticketshop/ticketshop.component';
import { WohngeldbescheidComponent } from './components/wohngeldbescheid/wohngeldbescheid.component';
import { DefaultCredentialComponent } from './components/default-credential/default-credential.component';
import { PartySelectorComponent } from './components/party-selector/party-selector.component';
import {PrivacyPoliceComponent} from './components/privacy-police/privacy-police.component';
import {TermsConditionsComponent} from './components/terms-conditions/terms-conditions.component';
import {DresdenPassComponent} from './components/dresdenpass/dresden-pass.component';
import {SccVerificationComponent} from './components/scc-verification/scc-verification.component';
import { SepaMandatePresentationComponent } from './components/sepa-mandate-presentation/sepa-mandate-presentation.component';
import { EuBankAccountComponent } from './components/eu-bank-account/eu-bank-account.component';
import { EuBankLoginComponent } from './components/eu-bank-login/eu-bank-login.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, data: { title: 'Hauptseite' } },
  { path: 'ticketshop', component: TicketshopComponent, data: { title: 'Ticketverkauf' }, },
  { path: 'ticketselection/:id', component: TicketBuyComponent, data: { title: 'Ticketverkauf' }, },
  { path: 'qrcode/:id', component: ShowQrCodeComponent, data: { title: 'Nachweis Herunterladen' }, },
  { path: 'credential', component: CredentialComponent, data: { title: 'Kommunale Datenkarte (KDK)' }, },
  { path: 'wohngeldbescheid', component: WohngeldbescheidComponent, data: { title: 'Wohngeldbescheid' } },
  { path: 'besuchernachweis', component: DefaultCredentialComponent, data: { title: 'Besuchernachweis' } },
  { path: 'besuchernachweis/:credentialStartType', component: DefaultCredentialComponent, data: { title: 'Besuchernachweis' } },
  { path: 'party-selector', component: PartySelectorComponent, data: { title: 'Standauswahl' } },
  { path: 'party-selector/:credentialStartType', component: PartySelectorComponent, data: { title: 'Standauswahl' } },
  { path: 'pp', component: PrivacyPoliceComponent, data: { title: 'Privacy Policy' } },
  { path: 'tc', component: TermsConditionsComponent, data: { title: 'Terms & Conditions' } },
  { path: 'ddp', component: DresdenPassComponent, data: { title: 'Sozialpass' } },
  { path: 'sccv', component: SccVerificationComponent, data: { title: 'SCC Besuchernachweis Validierung' } },
  { path: 'txcpp/de', component: PrivacyPoliceComponent, data: { pathString: 'txcpp/de', title: 'TicketXchange Datenschutzbestimmungen'}},
  { path: 'txcpp/en', component: PrivacyPoliceComponent, data: { pathString: 'txcpp/en', title: 'TicketXchange Privacy Policy'}},
  { path: 'sepamandate', component: SepaMandatePresentationComponent, data: { title: 'Erteilung des SEPA-Lastschriftmandats'}},
  { path: 'eubankaccount', component: EuBankAccountComponent, data: { title: 'Eröffnung eines Bankkontos'}},
  { path: 'eubanklogin', component: EuBankLoginComponent, data: { title: 'Ihr Bankkonto'}},
  { path: '**', /* 404 */ redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
