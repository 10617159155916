import { Injectable } from '@angular/core';
import { EnvService } from '../../environments/environment';
import { CredentialManifest } from '../models/credential-manifest.model';
import { Issuer } from '../models/issuer.model';
import { IssuanceIssueCredential } from '../models/messages/issuance-issue-credential.model';
import { IssuanceOfferCredential } from '../models/messages/issuance-offer-credential.model';
import { MessageContainer } from '../models/messages/messageContainer.model';
import { DataService } from './data.service';
import { LocalDataStorageService } from './local-data-storage.service';
import { WebsocketService } from './websocket.service';
import { ValidationRequestPresentationModel } from '../models/messages/validation-request-presentation.model';

@Injectable({
  providedIn: 'root'
})
export class CreateMessageService {
  websocketMessage: { [key: string]: any } = {};
  messageState: number = 0;
  message: { [key: string]: any } = {};
  credentialArray: any[] = [];
  isOk: boolean = false;
  inMessageError: boolean = false;
  ldDoc: { [key: string]: any } = {};
  outputDesc: { [key: string]: any } = {};
  schema: { [key: string]: any } = {};
  inputDesc: { [key: string]: any } = {};
  requestPresentations: string[] | {[key: string]: any}[] = [];


  constructor(private localDataStorageService: LocalDataStorageService, private dataService: DataService, private websocketService: WebsocketService,
    private envService: EnvService) { }

  getLdDoc() {
    return new Promise<Object>((resolve, reject) => {
      let url = this.envService.env['CS_URL'] + "/credentialSubject/v6"
      //debug url
      //let url = "http://localhost:4200/assets" + "/credentialSubject/v6/id-ideal-ld-doc-v6.jsonld"
      this.ldDoc = this.dataService.retrieveFile(url).then(result => {
        if (result instanceof Object) {
          let ldDocument = {
            id: url,
            doc: result
          }
          resolve(ldDocument);
        } else {
          reject('wrong type: ' + typeof result);
        }
      });
    });
  }

  getInputDescriptors(typeArray: any[]) {
    return new Promise<Object[]>((resolve, reject) => {
      let inputDescArr: { [key: string]: any }[] = []
      for (let i = 0; i < typeArray.length; i++) {
        if (typeArray[i] !== undefined) {
          inputDescArr.push(this.dataService.retrieveFile(window.origin + "/assets/inputdescriptors/v1/" + typeArray[i] + '.json'))
        }
      }
      Promise.all(inputDescArr).then(resultArr => {
        resolve(resultArr);
      })
    })
  }

  getOutputDescriptors(credentialIndex: number, outputdescriptorFragment?: string) {
    return new Promise<Object>((resolve, reject) => {
      if (this.credentialArray[credentialIndex]['type'] !== undefined) {
        this.outputDesc = this.dataService.retrieveFile(window.origin + "/assets/outputdescriptors/v1/" + this.credentialArray[credentialIndex]['type'][1] + outputdescriptorFragment + ".json").then(out => {
          if (out instanceof Object) {
            this.getSchemaFiles(credentialIndex).then(schemas => {
              this.getInputDescriptors(this.requestPresentations).then(inputDescs => {
                if (this.localDataStorageService.getData("purposeObjectArray").length > 0) {
                  this.replacePurposeOnInputDescriptors(inputDescs);
                }
                resolve({ 'out': out, 'schemas': schemas, "inputDescs": inputDescs })
              })
            });
          } else {
            reject('wrong type: ' + typeof out);
          }

          //});
        });
      } else {
        this.outputDesc = this.dataService.retrieveFile(window.origin + "/assets/outputdescriptors/v1/" + this.credentialArray[credentialIndex] + outputdescriptorFragment + ".json").then(out => {
          if (out instanceof Object) {
            this.getSchemaFiles(credentialIndex).then(schemas => {
              this.getInputDescriptors(Object.keys(this.requestPresentations)).then(inputDescs => {
                if (this.localDataStorageService.getData("purposeObjectArray").length > 0) {
                  this.replacePurposeOnInputDescriptors(inputDescs);
                }
                resolve({ 'out': out, 'schemas': schemas, "inputDescs": inputDescs })

              })
            });
          } else {
            reject('wrong type: ' + typeof out);
          }
        })
      }
    });
  }

  replacePurposeOnInputDescriptors(inputDescArray: {[key: string]: any}[]) {
    let purposeObjectArray = this.localDataStorageService.getData("purposeObjectArray");
    purposeObjectArray.forEach((purposeObj)  => {
      inputDescArray.forEach(inputDesc => {
        if (inputDesc.hasOwnProperty('name') && inputDesc.hasOwnProperty("id")) {
          let name = inputDesc['name'];
          if (inputDesc['id'].includes('eu_bank_account_ownership')) {name = "BADA"}
          if (typeof purposeObj === 'object' && typeof purposeObj[name] === 'object') {
            if (purposeObj[name]['purpose'] !== undefined) {
              inputDesc['purpose'] = purposeObj[name]['purpose'];
            }
          }
        }
      })

    })
  }

  getSchemaFiles(credentialIndex: number) {
    return new Promise((resolve, reject) => {
      if (this.credentialArray[credentialIndex]['type'] !== undefined) {
        this.schema = this.dataService.retrieveFile(window.origin + "/assets/schema/v1/" + this.credentialArray[credentialIndex]['type'][1].split('.')[0] + ".json").then(schema => {
          if (schema instanceof Object) {
            resolve(schema);
          } else {
            reject("wrong type: " + typeof schema);
          }
        });
      } else {
        this.schema = this.dataService.retrieveFile(window.origin + "/assets/schema/v1/" + this.credentialArray[credentialIndex] + ".json").then(schema => {
          if (schema instanceof Object) {
            resolve(schema);
          } else {
            reject("wrong type: " + typeof schema);
          }
        });
      }
    });
  }

  prepareMessage(issuer: Issuer, useCRE: boolean, isPaid: boolean, isFree: boolean, ldUrl?: string, manifest?: CredentialManifest, outputdescriptorFragment = "") {
    return new Promise((resolve, reject) => {
      this.credentialArray = this.localDataStorageService.getData("credentialArray");
      this.requestPresentations = this.localDataStorageService.getData("requestPresentation")
      console.warn(this.credentialArray);
      let promiseArray: Promise<any>[] = []
      let sessionId = this.localDataStorageService.getData("sessionId");
      let oobid = crypto.randomUUID();
      this.localDataStorageService.setData("oobid", oobid);
      let taskType = this.localDataStorageService.getData("taskType");
      let messageContainer = new MessageContainer(sessionId, oobid, taskType, this.credentialArray.length);
      let counter = 0;
      if ((taskType === 'de.kaprion.icp.s2p')) {
        this.getLdDoc().then(config => {
          if (taskType === "de.kaprion.icp.s2p") {
            for (let i = 1; i <= this.credentialArray.length; i++) {
              let messageObject = {};
              promiseArray[counter] = new Promise((resolve) => {
                resolve(
                  this.getOutputDescriptors(i - 1, outputdescriptorFragment).then((configs: any) => {
                    new Promise((resolve) => {
                      //resolve(
                      //this.getSchemaFiles(i - 1).then(schemas => {
                      if (!ldUrl) { ldUrl = this.envService.env['CS_URL'] + "/credentialSubject/v6"; }
                      if ((manifest === null || typeof manifest === "undefined") && outputdescriptorFragment.length === 0) {
                        manifest = new CredentialManifest("0.1.0", issuer, [configs.out], crypto.randomUUID(), []);
                      } else {
                        let idealIssuer = Issuer.getIDIdealIssuer();
                        manifest = new CredentialManifest("0.1.0", idealIssuer, [configs.out], crypto.randomUUID(), []);
                      }
                      let issuanceOfferCred = new IssuanceOfferCredential(oobid, "de.kaprion.icp.s2p", manifest, [this.credentialArray[i - 1]], [config], [configs.schemas as any], isPaid, isFree);
                      let issuanceIssueCred = new IssuanceIssueCredential(oobid, "de.kaprion.icp.s2p", [this.credentialArray[i - 1]], useCRE, undefined, "1.0");

                      Object.defineProperty(messageObject, "issuanceOfferCredential", {
                        enumerable: true,
                        configurable: false,
                        writable: true,
                        value: issuanceOfferCred.toJSON()
                      });
                      Object.defineProperty(messageObject, "issuanceIssueCredential", {
                        enumerable: true,
                        configurable: false,
                        writable: true,
                        value: issuanceIssueCred.toJSON()
                      });
                      messageContainer.setMessageObject(messageObject);
                      resolve(messageContainer)
                      //})
                      //)
                    })
                  })
                )
              });
              counter++;
            }
            //intermediate bugfixing for not properly stored messages
            this.getAllPromises(promiseArray, messageContainer).then(finalCheck => {
              if (messageContainer.getMessages().length < messageContainer.getTaskAmount()) {
                messageContainer.setTaskAmount(this.credentialArray.length);
              }
              resolve(messageContainer);
            });
          }
        });
      }
      if (taskType === 'de.kaprion.ppp.s2p') {
        console.log('DEBUG >> PPP');
        let messageObject = {};
        let nonce = this.localDataStorageService.getData('ticketIdArray')[0];
        console.log(JSON.stringify(this.credentialArray))
        let request = new ValidationRequestPresentationModel(oobid, nonce, this.requestPresentations);
        Object.defineProperty(messageObject, 'requestPresentation', {
          enumerable: true, configurable: false, writable: true, value: request.toJSON()
        });

        messageContainer.setMessageObject(messageObject);
        resolve(messageContainer);
        this.getAllPromises(promiseArray, messageContainer).then(finalCheck => {
          if (messageContainer.getMessages().length < messageContainer.getTaskAmount()) {
            messageContainer.setTaskAmount(messageContainer.getMessages().length);
          }
        });
      }
    });
  }

  prepareMessageV2(issuer: Issuer | null = null, oobid = "") {
    return new Promise((resolve, reject) => {
      this.credentialArray = this.localDataStorageService.getData('credentialArray');
      let promiseArray: Promise<any>[] = [];
      let sessionId = this.localDataStorageService.getData('sessionId');
      if (oobid.length === 0) {
        oobid = crypto.randomUUID();
      }
      this.localDataStorageService.setData('oobid', oobid);
      let taskType = this.localDataStorageService.getData('taskType');
      //let issuer = Issuer.getLHDIssuer(); //Issuer.getEhopIssuer(); // TODO als Parameter
      let messageContainer = new MessageContainer(sessionId, oobid, taskType, this.credentialArray.length);
      let counter = 0;
      if (taskType === 'de.kaprion.icp.s2p') {
        let outputDescArr = []
        let messageObject = {};
        let messageObjectArr = [];
        for (let i = 1; i <= this.credentialArray.length; i++) {
          let outputDesc = {
            type: this.credentialArray[i - 1]['type'][this.credentialArray[i - 1]['type'].length - 1].split(".")[0],
            subtype: this.credentialArray[i - 1]['type'][this.credentialArray[i - 1]['type'].length - 1].split(".").length === 2 ?
              "." + this.credentialArray[i - 1]['type'][this.credentialArray[i - 1]['type'].length - 1].split(".")[1] : "",
            language: "de"
          }
          outputDescArr.push(outputDesc);
          counter += 2;

          Object.defineProperty(messageObject, "cPersonalized", {
            enumerable: true,
            configurable: false,
            writable: true,
            value: [this.credentialArray[i - 1]]
          });
          Object.defineProperty(messageObject, "issuer", {
            enumerable: true,
            configurable: false,
            writable: true,
            value: issuer !== null ? issuer : ""
          });
          Object.defineProperty(messageObject, "useCre", {
            enumerable: true,
            configurable: false,
            writable: true,
            value: true
          });
          Object.defineProperty(messageObject, "isPaid", {
            enumerable: true,
            configurable: false,
            writable: true,
            value: false
          });
          Object.defineProperty(messageObject, "isFree", {
            enumerable: true,
            configurable: false,
            writable: true,
            value: true
          });
          Object.defineProperty(messageObject, "output_descriptors", {
            enumerable: true,
            configurable: false,
            writable: true,
            value: [outputDesc]
          })
          messageContainer.setMessageObject(messageObject);
        }


        //intermediate bugfixing for not properly stored messages
        this.getAllPromises(promiseArray, messageContainer).then(finalCheck => {
          if (messageContainer.getMessages().length < messageContainer.getTaskAmount()) {

            messageContainer.setTaskAmount(this.credentialArray.length);
          }
          resolve(messageContainer);
        });
      }
      if (taskType === 'de.kaprion.ppp.s2p') {
        console.log('DEBUG >> PPP');
        let messageObject = {};
        let nonce = this.localDataStorageService.getData('ticketIdArray')[0];
        console.log(JSON.stringify(this.credentialArray));
        let inputDescArr = [];
        for (let i = 0; i < this.credentialArray.length; i++) {
          let inputDesc = {
            type: this.credentialArray[i].split('.')[0],
            subtype: this.credentialArray[i].split('.')[1] !== undefined ? this.credentialArray[i].split('.')[1] : ""
          };
          inputDescArr.push(inputDesc);
        }
        let request = new ValidationRequestPresentationModel(oobid, nonce, inputDescArr);
        Object.defineProperty(messageObject, 'requestPresentation', {
          enumerable: true, configurable: false, writable: true, value: request.toJSON()
        });

        messageContainer.setMessageObject(messageObject);
        this.getAllPromises(promiseArray, messageContainer).then(finalCheck => {
          if (messageContainer.getMessages().length < messageContainer.getTaskAmount()) {
            messageContainer.setTaskAmount(messageContainer.getMessages().length);
          }
          resolve(messageContainer);
        });
      }
    });
  }

  // prepare message with combination of present proof and offer credential
  // https://gitlab.office.kaprion.de/Origins/Kaprion/id-ideal/web-demonstrator/-/issues/92/
  prepareMessageV3(issuer: Issuer, useCRE: boolean, isPaid: boolean, isFree: boolean, ldUrl?: string, manifest?: CredentialManifest, outputdescriptorFragment = "") {
    return new Promise((resolve, reject) => {
      this.credentialArray = this.localDataStorageService.getData("credentialArray");
      let promiseArray: Promise<any>[] = []
      let sessionId = this.localDataStorageService.getData("sessionId");
      let taskType = this.localDataStorageService.getData("taskType");
      let counter = 0;
      if ((taskType === 'cs.icp.split.offerCredential')) {
        let oobid = crypto.randomUUID();
        this.localDataStorageService.setData("oobid", oobid);
        let messageContainer = new MessageContainer(sessionId, oobid, "de.kaprion.icp.s2p", this.credentialArray.length, "cs.icp.split.offerCredential");
        this.requestPresentations = this.localDataStorageService.getData("requestPresentation");
        this.getLdDoc().then((config: any) => {
          if (this.credentialArray.length === 0 || this.credentialArray.length === 1) {
            for (let i = 1; i <= this.credentialArray.length; i++) {
              let issuanceOfferCred: IssuanceOfferCredential;
              let messageObject = {};
              promiseArray[counter] = new Promise((resolve) => {
                resolve(
                  this.getOutputDescriptors(i - 1, outputdescriptorFragment).then((configs: any) => {
                    new Promise((resolve) => {
                      if (!ldUrl) { ldUrl = this.envService.env['CS_URL'] + "/credentialSubject/v6"; }
                      if ((manifest === null || typeof manifest === "undefined") && outputdescriptorFragment.length === 0) {

                        if (this.requestPresentations.length > 0) {
                          if (Array.isArray(configs.inputDescs)) {
                            manifest = new CredentialManifest("0.1.0", issuer, [configs.out], crypto.randomUUID(), configs.inputDescs);
                          } else {
                            manifest = new CredentialManifest("0.1.0", issuer, [configs.out], crypto.randomUUID(), [])
                          }
                          issuanceOfferCred = new IssuanceOfferCredential(oobid, "de.kaprion.icp.s2p", manifest, [this.credentialArray[i - 1]], [config], [configs.schemas as any], false, true);
                          Object.defineProperty(messageObject, "issuanceOfferCredential", {
                            enumerable: true,
                            configurable: false,
                            writable: true,
                            value: issuanceOfferCred.toJSON()
                          });
                          messageContainer.setMessageObject(messageObject);
                          resolve(messageContainer)
                        };
                      };
                    });
                  }));
              });
            }
            //intermediate bugfixing for not properly stored messages
            this.getAllPromises(promiseArray, messageContainer).then(finalCheck => {
              if (messageContainer.getMessages().length < messageContainer.getTaskAmount()) {
                messageContainer.setTaskAmount(this.credentialArray.length);
              }
              resolve(messageContainer);
            });
          }
        });
      } else if (taskType === "cs.icp.split.issueCredential") {
        let oobid = this.localDataStorageService.getData("oobid");
        let messageContainer = new MessageContainer(sessionId, oobid, "de.kaprion.icp.s2p", this.credentialArray.length, "cs.icp.split.issueCredential");
        let issuanceIssueCred = new IssuanceIssueCredential(oobid, "de.kaprion.icp.s2p", this.credentialArray, true)
        for (let i = 1; i <= this.credentialArray.length; i++) {
          let issuanceObj = {};
          Object.defineProperty(issuanceObj, "issuanceIssueCredential", { value: issuanceIssueCred.toJSON(), writable: true, enumerable: true });
          messageContainer.setMessageObject(issuanceObj);
          if (i === this.credentialArray.length) {
            //intermediate bugfixing for not properly stored messages
            this.getAllPromises(promiseArray, messageContainer).then(finalCheck => {
              if (messageContainer.getMessages().length < messageContainer.getTaskAmount()) {
                messageContainer.setTaskAmount(this.credentialArray.length);
              }
              resolve(messageContainer);
            });
          }
        }
      }
    });
  }

  debugMessageContainer(object: { [key: string]: any }) {
    if (typeof object === 'object') {
      let keys: string[] = Object.keys(object);
      keys.forEach(key => {
        if (typeof object[key] === 'object') {
          this.debugMessageContainer(object[key]);
        }
      });
    }
  }

  getAllPromises(array: Promise<any>[], container: MessageContainer): Promise<any> {
    return new Promise((resolve, reject) => {
      Promise.all(array).then(finished => {
        resolve(container);
      });
    });
  }
}
