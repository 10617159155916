import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SchemaService {

  private credentialObj: {[key: string]: any};
  private credentialSchema: {[key: string]: any};
  
  constructor(private dataService: DataService) {
    this.credentialObj = {};
    this.credentialSchema = {};
  }

  updateValues(credentialObject: {[key: string]: any}, credentialSchema: {[key: string]:any}): Object {
    return {};
  }
}
