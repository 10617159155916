import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EvidenceModel } from 'src/app/models/w3c/vcdm/evidence.model';
import { CreateMessageService } from 'src/app/services/create-message.service';
import { CredentialBuilderService } from 'src/app/services/credential-builder.service';
import { DataService } from 'src/app/services/data.service';
import { LocalDataStorageService } from 'src/app/services/local-data-storage.service';
import { EnvService } from 'src/environments/environment';
import { BankAccountDisposalAuthorization } from 'src/app/models/kaprion/vc/Bank-account.model';
import { IbanGeneratorService } from 'src/app/services/iban-generator.service';
import { VerifiableCredential1xModel } from 'src/app/models/w3c/vcdm/verifiable-credential-1x.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KdkPersonModel } from 'src/app/models/kaprion/vc/kdk-person-k.model';
import { KdkBirthCertificateKModel } from 'src/app/models/kaprion/vc/kdk-birth-certifikate-k.model';
import { Issuer } from 'src/app/models/issuer.model';
import { MessageContainer } from 'src/app/models/messages/messageContainer.model';
import { GetLangPipe } from 'src/app/pipe/get-lang.pipe';

@Component({
  selector: 'app-eu-bank-account',
  templateUrl: './eu-bank-account.component.html',
  styleUrls: ['./eu-bank-account.component.scss']
})
export class EuBankAccountComponent implements OnInit {

  messageText: string = "";
  proofArray: string[] = [];
  sessionId: string = "";
  ticketIdArray: string[] = [];
  oobid: string = "";
  oobids: string[] = [];
  credentialArray: any[] = [];
  evidence: EvidenceModel[] = [];
  ibanInvalid = false;
  showQrCode = false;
  buttonText: string = "Konto eröffnen";
  credentialSubjects: { [key: string]: any }[] = [];
  appUrl: string = ""
  isRequestPresentation: boolean = true;
  credSubject: BankAccountDisposalAuthorization;
  requestPresentation: string[] = ["KDKPersonK", "KDKAddressK", "KDKBirthCertificateK"];
  statusOk: boolean = false;
  invalidProperty: string[] = [];
  alertMessage: string = "";
  hintMessage: string = "";
  isIssuance: boolean = false;
  displayObject: { [key: string]: any } = {}
  occurances: any[] = [];
  presentationID: string = "";
  generatedCredential: VerifiableCredential1xModel | null = null;
  canLogin: boolean = false;
  purposeObjectArray: {[key: string]: any}[] = [];

  messageArray: string[] = []

  //form
  bankAccountForm: FormGroup = new FormGroup({
    creditInstitution: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ])),
    brand: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ])),
    givenName: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ])),
    familyName: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(2)
    ])),
    iban: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(22)
    ])),
    bic: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(8)
    ])),
  });


  constructor(private dataService: DataService,
    private createMessageService: CreateMessageService,
    private localDataStorageService: LocalDataStorageService,
    private router: Router,
    private ibanGenService: IbanGeneratorService,
    private envService: EnvService,
    private csb: CredentialBuilderService) {
    this.credSubject = {
      id: "iss:REPLACE_ME_HOLDER",
      creditInstitution: "IDIdeal Bank",
      brand: "IDI Comfort",
      givenName: "",
      familyName: "",
      iban: this.ibanGenService.generateIban(), //valid Iban
      bic: "",
      isProtectedByCRE: true,
    };
  }

  ngOnInit(): void {
    this.sessionId = crypto.randomUUID();
    this.isIssuance = false;
    this.localDataStorageService.setData("sessionId", this.sessionId);
    this.localDataStorageService.setData("navigationalFragment", "/eu-bank-account");
    this.credSubject.bic = this.credSubject.iban.slice(4, 11) + "AB";
  }

  expandRequestPresentationByPurpose() {
    this.requestPresentation.forEach((cType: string, index: number) => {
      let purposeString: string = 'Ihre persönlichen Informationen werden für die Eröffnung eines Bankkontos angefragt';
      let obj = {
        [cType]: {
          'purpose': purposeString
        }
      }
      this.purposeObjectArray.push(obj);
      this.localDataStorageService.setData("purposeObjectArray", this.purposeObjectArray)
    })
  }

  private genPPPMessage() {
    return new Promise((resolve, reject) => {
      this.presentationID = crypto.randomUUID();
      this.localDataStorageService.setData("ticketIdArray", [this.presentationID]); // presentation_definition.id
      this.localDataStorageService.setData("taskType", "de.kaprion.ppp.s2p");
      this.localDataStorageService.setData("requestPresentation", this.requestPresentation)
      this.createMessageService.prepareMessage(Issuer.getIDIBankIssuer(), true, false, true).then((msg: any) => {
        if (msg instanceof MessageContainer) {
          if (msg.getMessages().length < msg.getTaskAmount()) {
            msg.setTaskAmount(msg.getMessages().length);
          }

          msg = JSON.stringify(msg, (key, value) => {
            if (value === undefined || value === null) {
              return undefined;
            }
            // Filtering null and undefined values from arrays
            if (Array.isArray(value)) {
              return value.filter((item) => item !== null && item !== undefined);
            }
            return value;
          });

          this.dataService.sendExpectJSON(msg, this.envService.env['CS_URL'] + '/initSessionValidation').then((response: any) => {

            let attach = response['credentialApplication']['attachments']['data']['json']['verifiableCredential'];
            if (Array.isArray(attach)) {
              this.credentialArray = attach;
            }
            else {
              this.credentialArray.push(attach);
            }
            console.log(this.credentialArray);
            this.credentialArray.forEach(credential => {
              if ((<VerifiableCredential1xModel>credential).type.includes("BADA")) {
                this.processReceivedVC(<VerifiableCredential1xModel>credential);
              }
            });
            resolve(this.credentialArray);
          }).catch((e: any) => {
            console.warn("Rejected: " + e);
            reject("Something went wrong: " + e)
          });
        }
      });
      this.appUrl = this.envService.env['VCISS_URL'] + "/verify" +
        "?_oobid=" + this.presentationID +
        "&credentialProviderLink=" + this.envService.env['VCISS_URL'] + "/validation";
      this.showQrCode = true;
      this.isRequestPresentation = true;

    })
  }

  reset(): void {
    this.sessionId = this.localDataStorageService.getData("sessionId");
    this.buttonText = "Konto eröffnen";
    this.credentialArray = [];
    this.appUrl = "";
    this.showQrCode = false;
    this.proofArray = [];
    this.evidence = [{
      docId: '',
      documentPresence: 'Digital',
      evidenceDocument: '',
      id: this.envService.env['HOME_URL'] + '/PhotoVerification/',
      subjectPresence: 'Physical',
      type: ['DocumentVerification'],
      verifier: 'KAPRION Demo Shop User'
    }]
  }

  onSubmit(): void {
    if (this.proofArray.length !== this.requestPresentation.length) {
      this.issueBada();
    } else {
      this.reset()
    }
  }

  onLogin(): void {
    this.localDataStorageService.setData("credentialArray", ["BADA"]); // credential to be requested
    this.genPPPMessage().then((credentialArr: any) => {
      this.localDataStorageService.setData("loginInformation", credentialArr[0]['credentialSubject']);
      this.router.navigate(["eubanklogin"])
    }).catch(e => {
      let dialogList = document.getElementsByTagName("dialog-box-presentation-invalid");
      (dialogList[0] as any).showModal()
    });
  }

  checkRequestedItem(requestedItem: string) {
    return this.requestPresentation.includes(requestedItem);
  }

  issueBada() {
    this.isRequestPresentation = false;
    let credentialContext = this.envService.env['CS_URL'] + "/credentialSubject/v6";
    let id = this.envService.env['HOME_URL'] + "/samples/KAPRION/SEPADirectDebitMandate/" + crypto.randomUUID();
    this.localDataStorageService.setData("taskType", "cs.icp.split.offerCredential");
    this.localDataStorageService.setData("requestPresentation", this.requestPresentation);
    this.expandRequestPresentationByPurpose();
    let credential = this.csb
      .id(id)
      .addContext(credentialContext)
      .addType("BADA")
      .issuanceDate()
      .expirationDate(this.csb.getDateTime(true, 1))
      .credentialSubject(this.credSubject)
      .evidence(this.evidence)
      .build();
    this.credentialArray.push(credential);
    this.localDataStorageService.setData("credentialArray", this.credentialArray);
    let message = this.createMessageService.prepareMessageV3(Issuer.getIDIBankIssuer(), true, true, false).then(msg => {
      this.appUrl = this.envService.env['VCISS_URL'] + "/issue" +
        "?_oobid=" + this.localDataStorageService.getData("oobid") +
        "&credentialProviderLink=" + this.envService.env['VCISS_URL'] + "/issuance";
      this.showQrCode = true;
      if (msg instanceof MessageContainer) {
        msg = JSON.stringify(msg, (key, value) => {
          if (value === undefined || value === null) {
            return undefined;
          }
          if (Array.isArray(value)) {
            return value.filter((item) => item !== null && item !== undefined);
          }
          return value;
        });
        this.dataService.send(msg, this.envService.env['VCISS_URL'] + '/initSession', true).then((response: any) => {
          if (response['type'] === "de.kaprion.icp.s2p.issueCredential.req") {
            this.statusOk = true;
            let attach = response['credentialApplication']['verifiableCredential'];
            attach.forEach((credential: any) => {
              this.requestPresentation.forEach(requested => {
                if (credential.type.includes(requested)) {
                  this.processReceivedVC(<VerifiableCredential1xModel>credential);
                }
              })
              // if ((<VerifiableCredential1xModel>credential).type.some(requested => this.checkRequestedItem(requested))) {
              //   console.warn((<VerifiableCredential1xModel>credential).type.some(requested => this.checkRequestedItem(requested)))
              //   this.processReceivedVC(<VerifiableCredential1xModel>credential);
              // }
            });
            if (this.proofArray.length === this.requestPresentation.length) {
              this.isIssuance = true;
              this.displayObject = <Object>this.credSubject;
              //this.messageText = "Ihr Konto wird nun eröffnet.";
              let credential = this.csb
                .id(id)
                .addContext(credentialContext)
                .addType("BADA")
                .issuanceDate()
                .expirationDate(this.csb.getDateTime(true, 1))
                .credentialSubject(this.credSubject)
                .evidence(this.evidence)
                .build();
              this.localDataStorageService.setData("credentialArray", [credential]);
              this.localDataStorageService.setData("taskType", "cs.icp.split.issueCredential");
              this.showQrCode = false;
              let finalMessage = this.createMessageService.prepareMessageV3(Issuer.getLHDIssuer(), true, true, false).then(finalMsg => {
                if (finalMsg instanceof MessageContainer) {
                  finalMsg = JSON.stringify(finalMsg, (key, value) => {
                    if (value === undefined || value === null) {
                      return undefined;
                    }
                    // Filtering null and undefined values from arrays
                    if (Array.isArray(value)) {
                      return value.filter((item) => item !== null && item !== undefined);
                    }
                    return value;
                  });
                  this.dataService.send(finalMsg, this.envService.env['VCISS_URL'] + '/initSession').then((response: any) => {
                    if (response['ok']) {
                      this.statusOk = true;
                      this.isIssuance = false;
                      if (credential.type.includes("SEPADirectDebitMandate"))
                        this.generatedCredential = credential;
                      this.buttonText = "Anmelden";
                      //this.canLogin = true;
                    } else {
                      this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['status'];
                      this.messageArray.push(this.messageText);
                      this.statusOk = false;
                    }
                  });
                }
              })
            } else {
              this.messageText = "Vorgelegte Nachweise erfüllen nicht die Anforderung zur Ausstellung des Bank-Credentials."
              this.messageArray.push(this.messageText);
              this.hintMessage = "Folgende Nachweise erfüllen nicht die Anforderungen: " + this.checkEntry()
              this.statusOk = false;
            }
          } else {
            this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['status'];
            this.messageArray.push(this.messageText);
            this.statusOk = false;
          }
          this.credentialArray = [];
          // alert(response) // TODO Updatet for better Message -> Dialog Box
          this.reset();
        }).catch((e) => {

          console.warn("Rejected: " + e);
        });
      }
    });
  }

  checkEntry() {
    return this.requestPresentation.filter(entry => !this.proofArray.includes(entry));
  }

  processReceivedVC(credential: VerifiableCredential1xModel) {
    let vcType = this.evaluateForm(credential)
    if (vcType.length > 0) {
      let evidence: EvidenceModel = {
        docId: (' ' + credential.id).slice(1),
        documentPresence: 'Digital',
        evidenceDocument: vcType,
        id: '__HOME_URL__/Verification/' + crypto.randomUUID(),
        subjectPresence: 'Physical',
        type: ['DocumentVerification'],
        verifier: 'https://demo.shop.it.kaprion.net/samples/KAPRION/SysActor/8506cbb4-c8d0-4b34-8f93-c5638893f0e2'
      };
      this.proofArray.push(vcType);
      this.evidence.push(evidence);
    } else {
      const langPipe = new GetLangPipe();
      let requestedItem = langPipe.transform(vcType);
      this.alertMessage = "Typ des Nachweises nicht gültig. Vorgang wird abgebrochen." + " Typ: " + vcType;
      this.hintMessage = "Der Nachweis " + requestedItem + " ist fehlerhaft. Bitte lassen Sie sich " + requestedItem + "erneut ausstellen."
      let dialogList = document.getElementById("dialog-box-alert");
      (dialogList as any).showModal();
    }
  }

  evaluateForm(cred: VerifiableCredential1xModel): string {
    if (cred.type.includes('KDKPersonK')) {
      let subject = cred.credentialSubject as KdkPersonModel;
      let obj = {
        givenName: subject.givenName,
        familyName: subject.familyName
      }
      this.occurances[0] = obj;
      return "KDKPersonK"
    } else if (cred.type.includes('KDKBirthCertificateK')) {
      let subject = cred.credentialSubject as KdkBirthCertificateKModel
      let obj = {
        givenName: subject.givenName,
        familyName: subject.familyName
      }
      this.occurances[1] = obj;
      return "KDKBirthCertificateK"
    } else if (cred.type.includes('KDKAddressK')) {
      return "KDKAddressK";
    } else if (cred.type.includes('SEPADirectDebitMandate')) {
      return "SEPADirectDebitMandate";
    } else {
      return ""
    }
  }

  countOccurance(array: any[], value: any): number {
    let count: number = 0;
    array.forEach((i: any) => {
      if (i === value) { count++ }
    })
    return count;
  }

  //abort-functionality | back-button
  onClickBack() {
    this.buttonText = "Konto eröffnen";
  }

  onClickCancel() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).showModal();
  }

  // request confirmation
  onDialogCancel() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).close();
  }

  onDialogOk() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).close();
    this.localDataStorageService.setData("navigationalFragment", "");
    this.router.navigate(["home"]);
  }

  onDialogIbanOk() {
    let dialogList = document.getElementById("dialog-box-iban");
    (dialogList as any).close();
  }

  onDialogBack() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[1] as any).close();
    this.sessionId = this.localDataStorageService.getData("sessionId");
    this.buttonText = "Eingaben prüfen";
    this.credentialSubjects = [];
    this.credentialArray = [];
    this.appUrl = "";
    this.showQrCode = false;
  }

  onDialogNameInvalid() {
    let dialogList = document.getElementById("dialog-box-name-invalid");
    (dialogList as any).close();
  }

  onDialogPresentationInvalid() {
    let dialogList = document.getElementById("dialog-box-presentation-invalid");
    (dialogList as any).close();
    this.router.navigate(['home'])
  }

}
