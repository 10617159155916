<header class="heading">Sozialpass am Beispiel der Landeshauptstadt Dresden (Minimalsetup)</header>

<div class="main-wrapper container-fluid">
  <!-- BEGIN aside box -->
  <div *ngIf="!showQrCode" class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div class="card border-0 card_shadow">
      <div class="card-body">
        <p>
          Anwendungsfall Sozialpass (z.B. der Stadt Dresden)
        <ol>
          <li>Abfrage des KDK Bildnachweises</li>
          <li>Abfrage des Wohngeldbescheids.</li>
          <li>Herausgabe des Sozialpasses</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- END aside box -->

  <!-- BEGIN content box -->
  <!--
  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" [ngClass]="showQrCode ? 'mt-6': 'mt0'">
    <div class="card border-0 card_shadow">
      <!--<div class="card-body" id="content-box">-->
        
  <!--</div>-->
  <div class="flex-container">
    <div class="main-wrapper">
<!--     <div class="main-wrapper container-fluid" [ngClass]="imageSource.length > 0 ? 'mt-6' : 'mt0'"
      [hidden]="imageSource.length === 0">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mx-auto p-3 pt-3">
        <div class="card border-0 card_shadow">
          <div class="card-body">
            <div id="content-box">
              <div id="response">
                <img *ngIf="imageSource.length > 0" src="{{imageSource}}" class="rounded mx-auto d-block"
                  style="min-width: 250px; max-width: 300px; object-fit: cover;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    </div>
  </div>
  <!--BEGIN WG Table-->
  <!--<div class="main-wrapper container-fluid" *ngIf="wg.issued.length !== 0 && isWG === true">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mx-auto p-3 pt-3">
        <div class="card border-0 card_shadow">
          <div class="card-body">
            <div id="content-box">
              <div id="response1">
                <div class="table-responsive-sm" id="table">
                  <table class="table table-hover table-md">
                    <tr>
                      <td>G&uuml;ltig von:</td>
                      <td>{{wg.issued}}</td>
                    </tr>
                    <tr>
                      <td>G&uuml;ltig bis:</td>
                      <td>{{wg.expires}}</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <h3 class="mt-md-4">{{wg.wohngeld.typ}}</h3>
                      </td>
                    </tr>
                    <tr>
                      <td>Erstantrag:</td>
                      <td>
                        {{wg.wohngeld.complies}}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <h3 class="mt-md-4">Ihre Angaben</h3>
                      </td>
                    </tr>
                    <tr>
                      <td>bruttoEinkommen:</td>
                      <td>
                        {{wg.wohngeld.ihreAngaben.bruttoEinkommen}}
                      </td>
                    </tr>
                    <tr>
                      <td>bruttoMiete:</td>
                      <td>
                        {{wg.wohngeld.ihreAngaben.bruttoMiete}}
                      </td>
                    </tr>
                    <tr>
                      <td>Anz. Wohngeldberechtigt:</td>
                      <td>{{wg.wohngeld.ihreAngaben.anzWohngeldBerechtigt}}</td>
                    </tr>
                    <tr>
                      <td>Kinderbetreuungskosten:</td>
                      <td>
                        {{wg.wohngeld.ihreAngaben.kinderbetreuungskosten}}
                      </td>
                    </tr>
                    <tr>
                      <td>Mietenstufe:</td>
                      <td>{{wg.wohngeld.ihreAngaben.mietenstufe}}</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <h3 class="mt-md-4">Berechnung</h3>
                      </td>
                    </tr>
                    <tr>
                      <td>Werbungskosten:</td>
                      <td>
                        {{wg.wohngeld.berechnung.werbungskosten}}
                      </td>
                    </tr>
                    <tr>
                      <td>Gesamteinkommen nach Abz&uuml;egen:</td>
                      <td>
                        {{wg.wohngeld.berechnung.gesamtEinkommenNachAbzuegen}}
                      </td>
                    </tr>
                    <tr>
                      <td>Ber&uuml;ksichtigung Miete:</td>
                      <td>
                        {{wg.wohngeld.berechnung.berueksichtigungMiete}}
                      </td>
                    </tr>
                    <tr>
                      <td>Betriebskosten Abzug:</td>
                      <td>
                        {{wg.wohngeld.berechnung.betriebskostenAbzug}}
                      </td>
                    </tr>
                    <tr>
                      <td>Heizkostenpauschale:</td>
                      <td>
                        {{wg.wohngeld.berechnung.heizkostenpauschale}}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                    </tr>
                    <tfoot>
                      <td><strong>Gesamtbetrag:</strong></td>
                      <td>{{wg.wohngeld.gesamtbetrag}}</td>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->
  <!--END WG TABLE-->
  <!-- END ContentBOX-->
  <app-presentation *ngIf="proofArray.length === 2" [proofArray]="proofArray"></app-presentation>
  <!-- BEGIN button wrapper -->
  <div style="display: block;" class="vh-73">
    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="showQrCode">
      <div class="card border-0 card_shadow">
        <div id="content-box">
          <app-showqrcode *ngIf="showQrCode" class="qr-code" [verificationUrl]="appUrl"
          [isRequestPresentation]="isRequestPresentation" [isDresdenPass]="false"></app-showqrcode>
      </div>
      </div>
    </div>
    <div [ngClass]="{'flex-wrapper': true, 'qrcode-flex-wrapper': showQrCode && proofArray.length < 2}">
      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
      <button *ngIf="(proofArray.length === 0 || proofArray.length === 2) && !showQrCode" type="submit" (click)="onSubmit()" class="link-box-white">{{buttonText}}</button>
      <button *ngIf="proofArray.length === 0" id="buttonCancel" (click)="reset()" class="link-box-white">Abbrechen</button>
          </div>
    </div>
  </div>
<!--</div>-->
  <!-- END button wrapper -->
<!--</div>-->
<ng-container *ngFor="let message of messageArray">
<app-toaster *ngIf="message.length > 0" [messageText]="message" [status]="statusOk"></app-toaster>
</ng-container>
