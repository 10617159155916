import { Injectable } from "@angular/core";
//import { DataService } from "src/app/services/data.service";

export interface Environment {
  CONFIGURATION: string, //build environment
  HOME_URL: string, // address home
  CS_URL: string, // address control-service
  VCISS_URL: string, // address control-service   
}

export let environment = {
  imgURL: 'assets/images/',
  timestamp: "2024-07-26 11:20",
  production: false,

  assets: 'assets/',
  development: {
    DEBUG: true,
    HOME_URL: "http://localhost:4200", // address home
    CS_URL: "http://localhost:8082/ControlService", // address control-service
    VCISS_URL: "http://localhost:8083/DatabaseController", // address control-service
    bannerText: "Development Testsystem Local"
  },
  ET: {
    DEBUG: true,
    HOME_URL: "http://localhost:4200", // address home
    CS_URL: "http://localhost:8082/ControlService", // address control-service
    VCISS_URL: "http://localhost:8083/DatabaseController",
    bannerText: "Development Testsystem ET"
  },
  IT: {
    DEBUG: false,
    HOME_URL: "http://localhost:4200", // address home
    CS_URL: "http://localhost:8082/ControlService", // address control-service
    VCISS_URL: "http://localhost:8083/DatabaseController",
    bannerText: "Integration Testsystem IT"
  },
  PS: {
    DEBUG: false,
    HOME_URL: "http://localhost:4200", // address home
    CS_URL: "http://localhost:8082/ControlService", // address control-service
    VCISS_URL: "http://localhost:8083/DatabaseController",
    bannerText: "Production System PS"
  }
}

@Injectable({ providedIn: 'root' })
export class EnvService {
  private _env: { [key: string]: any } = environment.development;
  private _apiUrl: string = ""

  get env(): { [key: string]: any } {
    return this._env
  }

  set env(system: any) {
    this._env = (environment as { [key: string]: any })[system]
  }

  get apiUrl(): string {
    return this._apiUrl
  }

  constructor() {
    this.init();
  }

  init(): Promise<void> {
    return new Promise((resolve) => {
      //this.setEnvVariables()
      this.getEnvJson().then((retrievedEnvironment: Environment) => {
        this.setEnvVariables(retrievedEnvironment)
        resolve()
      }).catch(() => { alert("Error occured on initializing environment from docker-configuration. Falling back to localhost") })
    })
  }

  // private setEnvVariables(): void {
  //   const hostname = window && window.location && window.location.hostname;

  //   if (/^.*localhost.*/.test(hostname)) {
  //     this._env = environment.ETS;
  //     this._apiUrl = '/api';
  //   } else if (/^demo.shop.kaprion.net/.test(hostname)) {
  //     console.log(`environment ET`);
  //     this._env = environment.ET;
  //   } else if (/^demo.shop.it.kaprion.net/.test(hostname)) {
  //     console.log(`environment IT`);
  //     this._env = environment.IT;
  //   } else if (/^demo.shop.ps.kaprion.net/.test(hostname)) {
  //     console.log(`environment PS`);
  //     this._env = environment.PS;
  //   } else {
  //     console.log(`unknown, environment ET`);
  //     console.error(`Cannot find environment for host name ${hostname}`);
  //   }
  // }

  private setEnvVariables(conf: Environment) {
    let config = conf.CONFIGURATION as keyof typeof environment
    if (Object.keys(environment).includes(config)) {
      environment = { ...environment,
        [config]: {
          HOME_URL: conf.HOME_URL,
          CS_URL: conf.CS_URL,
          VCISS_URL: conf.VCISS_URL,
        }

      }
      this.env = config
      // Object.defineProperties(environment[config], {
      //   HOME_URL: { enumerable: true, writable: true, value: env.HOME_URL },
      //   CS_URL: { enumerable: true, writable: true, value: env.CS_URL },
      //   VCISS_URL: { enumerable: true, writable: true, value: env.VCISS_URL },
      // })
    } else {
      console.error("Invalid Configuration: Provide URLs to docker-compose.yml")
    }
  }

  private getEnvJson(): Promise<Environment> {
    return new Promise((resolve, reject) => {
      fetch(window.location.origin + "/config/config.json").then((response: Response) => {
        if (response.ok) {
          let responseBody = response.json().then(body => {
            if (this.isEnvironment(body)) {
              resolve(body);
            } else {
              reject("Environment not valid.")
            }
            return body
          }).catch(() => {
            return undefined
          })
          return responseBody
        } else {
          return undefined
        }
      })
    })
  }

  isEnvironment(obj: Environment | any): obj is Environment {
    if ((obj as Environment).CONFIGURATION !== undefined
      && (obj as Environment).HOME_URL !== undefined
      && (obj as Environment).CS_URL !== undefined
      && (obj as Environment).VCISS_URL !== undefined) {
      return true;
    }
    else {
      return false;
    }
  }
}
