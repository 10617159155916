<header class="heading">Eröffnung eines Bankkontos</header>
<div class="main-wrapper container-fluid" style="display: block;">
    <div *ngIf="!statusOk && requestPresentation && !showQrCode"
        class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
        <div class="card border-0 card_shadow">
            <div class="card-body">

                <p>Wir freuen uns, dass Sie ein Bankkonto bei uns eröffnen möchten.</p>
                <p>Die folgenden Schritte werden durchgeführt:</p>
                <p>Wenn Sie den Prozess starten wird Ihnen ein QR-Code angezeigt. Scannen Sie diesen bitte mit Ihrer IDeal
                    Wallet App. Sie werden im Anschluss gebeten, die folgenden drei Nachweise zu präsentieren:</p>
                <ol>
                    <li>KDK Person</li>
                    <li>KDK Geburtsurkunde</li>
                    <li>KDK Adresse.</li>

                </ol>
                <p>Diese Informationen benötigen wir um entsprechend der PSD2-Verordnung Ihr Bankkonto eröffnen zu
                    dürfen.</p>
                <p>Wir werden diese Daten für die Dauer unserer gemeinsamen Geschäftsbeziehung sicher verwahren.</p>
                <p>Mit der Eröffnung Ihres Bankkontos stellen wir Ihnen ein Bankkonto-Verfügungsberechtigungs-Credential
                    aus.</p>
                <p>Mit diesem können Sie Dritten gegenüber nachweisen, dass Sie Verfügungsberechtigter über dieses
                    Bankkonto sind.</p>
            </div>
        </div>
    </div>
    <app-presentation *ngIf="proofArray.length === 3" [proofArray]="proofArray"></app-presentation>

    <div *ngIf="isIssuance">
        <div>
            <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
                <div class="card border-0 card_shadow">
                    <div class="card-body">
                        <h4>Ihr Konto wird nun eröffnet.</h4>
                        <p>Bitte halten Sie Ihre IDeal Wallet aktiv. Ihr Bankkonto-Verfügungsberechtigungs-Credential
                            wird ausgestellt.</p>
                        <div class="row mx-auto mb-3" *ngFor="let entry of displayObject | keyvalue">
                            <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                                <div class="col-elementLabel">
                                    <div class="elementValue">
                                        <label>{{entry.key | getLang}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-8 col-md-8 col-sm-8">
                                <div class="col-elementValue">
                                    <div class="elementValue">
                                        <p>{{entry.value | getLang}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="statusOk && messageText.length === 0 && !isIssuance"
        class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
        <div class="card border-0 card_shadow">
            <div class="card-body">

                <p>Herzlichen Glückwunsch zu Ihrem Bankkonto.</p>
                <p><strong>Kreditinstitut</strong> {{credSubject.creditInstitution}}</p>
                <!--<p><strong>Kontoinhaber</strong> {{credSubject.givenName}} {{credSubject.familyName}}</p>-->
                <p><strong>IBAN</strong> {{credSubject.iban}}</p>
                <p><strong>BLZ</strong> {{credSubject.bic}}</p>
            </div>
        </div>
    </div>
    <div style="display: block;" class="vh-73" *ngIf="!isIssuance">
        <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="showQrCode">
            <div class="card border-0 card_shadow">
                <!--<div class="card-body" id="content-box">-->
                <app-showqrcode class="qr-code" *ngIf="showQrCode" [verificationUrl]="appUrl"
                    [isRequestPresentation]="isRequestPresentation" [isDresdenPass]="false"></app-showqrcode>
            </div>
        </div>
        <div
            [ngClass]="{'flex-wrapper': true, 'none': (showQrCode && proofArray.length <3) || (!showQrCode && proofArray.length === 1)}">
            <button *ngIf="!showQrCode && !isIssuance && !statusOk" type="submit" (click)="onSubmit()"
                class="link-box-white">{{buttonText}}</button>
            <!-- <div class="block" *ngIf="canLogin">
                <p><strong>Sie können sich nun in Ihrem Online-Banking-Account anmelden.</strong></p>
                <button *ngIf="canLogin" type="submit" (click)="onLogin()"
                    class="link-box-white">{{buttonText}}</button>
            </div> -->
        </div>
    </div>


</div>
<dialog id="dialog-box">
    <h3>Möchten Sie die Eingabe Ihrer Daten beenden und zur Credentialauswahl zurückkehren?</h3>
    <div class="inline">
        <button (click)="onDialogCancel()" class="link-box-white">Nein</button>
        <button (click)="onDialogOk()" class="link-box-white">Ja</button>
    </div>
</dialog>
<dialog id="dialog-box-alert">
    <h3>Es ist ein Fehler aufgetreten</h3>
    <p>{{alertMessage}}</p>
    <p *ngIf="hintMessage.length > 0">{{hintMessage}}</p>
    <div class="inline">
        <button (click)="onDialogBack()" class="link-box-white">Ok</button>
    </div>
</dialog>
<dialog id="dialog-box-name-invalid">
    <h3>Der Abgleich ihres angegebenen Namens mit dem Personennachweises ist negativ. Folgende Eigenschaften stimmen nicht
        überein:</h3>
    <h3 *ngFor="let prop of invalidProperty">{{prop | getLang}}</h3>
    <p>Bitte prüfen Sie Ihre Eingaben.</p>
    <div class="inline">
        <button (click)="onDialogNameInvalid()" class="link-box-white">Ok</button>
    </div>
</dialog>
<dialog id="dialog-box-presentation-invalid">
  <h3>Die Präsentation des Bank-Verfügungscredentials war nicht erfolgreich.</h3>
  <p>Bitte versuchen Sie es erneut</p>
  <div class="inline">
      <button (click)="onDialogPresentationInvalid()" class="link-box-white">Ok</button>
  </div>
</dialog>
<ng-container *ngFor="let message of messageArray">
    <app-toaster *ngIf="message.length > 0" [messageText]="message" [status]="statusOk"></app-toaster>
</ng-container>