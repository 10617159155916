import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-privacy-police',
  templateUrl: './privacy-police.component.html',
  styleUrls: ['./privacy-police.component.scss']
})
export class PrivacyPoliceComponent implements OnInit {

  isIdIdeal: boolean = true;
  headerString: string = "IDeal Wallet Beta app";
  lang: string = "en";
  routerParams: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,) { 
    this.activatedRoute.data.subscribe(params => this.routerParams = params);
  }

  ngOnInit(): void {
    console.log(this.routerParams)
    if (this.routerParams['pathString'] !== undefined) {
      if (this.routerParams['pathString'].includes('txcpp')) {
        this.isIdIdeal = false;
        if (this.routerParams['pathString'].split("/").length === 2) { 
          this.lang = this.routerParams['pathString'].split("/")[1];
          console.log(this.lang)
        }
      }
      this.headerString = this.isIdIdeal ? "IDeal Wallet Beta App" : "ticketXchange App";
    }
  }

  onClickBack() {
    this.router.navigate(['home']);
  }

}
