<header class="heading">Wohngeldbescheid</header>

<div class="main-wrapper container-fluid">
  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div class="card border-0 card_shadow">
      <div class="card-body">
        <p>Für den Anwendungsfall Sozialpass ist ein zusätzlicher Nachweis erforderlich, der in der Regel von einem
          Sozialamt ausgestellt wird. Zur Vereinfachung der Demonstration wird hier nur ein Wohngeldbescheid ausgegeben.
        </p>
      </div>
    </div>
  </div>

  <!--<div class="flex-wrapper">
    <button type="submit" (click)="onFinalSubmit()" class="link-box-white">Nachweis Abholen</button>
    <button (click)="onClickBack()" class="link-box-white">Abbrechen</button>
</div>-->

  <!--</div>-->

  <dialog id="dialog-box">
    <h3>Möchten Sie die Eingabe Ihrer Daten beenden und zur Credentialauswahl zurückkehren?</h3>
    <div class="inline">
      <button (click)="onDialogCancel()" class="link-box-white">Nein</button>
      <button (click)="onDialogOk()" class="link-box-white">Ja</button>
    </div>
  </dialog>
  <div style="display: block;" class="vh-73">
    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="showQRCode">
      <div class="card border-0 card_shadow">
        <!--<div class="card-body" id="content-box">-->
        <app-showqrcode class="qr-code" *ngIf="showQRCode" [isRequestPresentation]="false"></app-showqrcode>
      </div>
    </div>
    <div [ngClass]="{'flex-wrapper': true}">
      <button *ngIf="!showQRCode" type="submit" (click)="onFinalSubmit()" class="link-box-white">Nachweis
        Abholen</button>
      <!--<button *ngIf="!showQrCode && !isInput" (click)="requestPresentation()" class="link-box-white">Prozess starten</button>-->
      <button (click)="onClickBack()" class="link-box-white" *ngIf="!showQRCode">Abbrechen</button>
    </div>
  </div>
</div>
  <!--<app-showqrcode *ngIf="showQRCode" [isRequestPresentation]="false"></app-showqrcode>-->
  <app-toaster *ngIf="messageText.length > 0" [messageText]="messageText" [status]="statusOk"></app-toaster>