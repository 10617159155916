// service to handale request to server side

import { HttpErrorResponse} from '@angular/common/http';
//import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalDataStorageService } from './local-data-storage.service';
import { UniqueIdentifiersService } from './unique-identifiers.service';
import { EnvService, environment } from 'src/environments/environment';

@Injectable()
export class DataService {

  pthid: string = '';
  senderDid: string = '';
  media_type: string = 'application/json';

  promiseArray: Promise<any>[] = [];
  hasReachedTimeoutForResponse = false;
  private _blnStatusSuccess: boolean[] = [];
  private _statusMessageArray: Object[] = [];

  // private http: HttpClient = new HttpClient(new HttpXhrBackend({
  //   build: () => new XMLHttpRequest()
  // }));

  constructor(private localDataStorageService: LocalDataStorageService, private uniqueIdentService: UniqueIdentifiersService, private envService: EnvService) {
  }

  /**
   * to send request to server side
   * @param data
   * @returns status
   */

  get blnStatusSuccess() {
    return this._blnStatusSuccess;
  }

  /* sendXHRrequest(urlString: string): Promise<any> {
      return new Promise((resolve, reject) => {
          this.getRequestMethod("POST", urlString).then(response => {
              console.info("Succeeded: XHR Request")
              resolve(true);
          }).catch((e: HttpErrorResponse) => {
              if (e === undefined) {
                  console.warn("No answer from control-service received. Resolving ...");
                  resolve(true);
                  return true;
              } else {
                  console.log(e.statusText);
                  this._statusMessageArray.push({ xhr: "XHR-Request failed: " + e.statusText });
                  alert("Not allowed to execute POST-method: " + e.error);
                  return e.error;
              }
          });
      });
  } */

  // TODO modify function that both situations can be handled
  send(data: any, urlString: string, isResponseProcessed = false): Promise<any> {
    return new Promise((resolve, reject) => {

      if (data !== undefined) {
        /* this.sendXHRrequest(urlString).then(xhrComplete => {
            if (xhrComplete === true) {
                console.warn("XHR-Request without response. Trying to progress..."); */


        // const xhrPromise = new Promise((resolve, reject) => {
        //     resolve(this.getRequestMethod('POST', urlString));
        //     reject("Weren't granted to use method on \n https://mags.kt.et.kaprion.de/");
        // });
        // xhrPromise.then(xhrResponse => {
        data = this.replacePlaceHolder(data);

        //         const messagePromise = new Promise((resolve, reject) => {
        //           const headers = new HttpHeaders({
        //             'Content-Type': 'application/json',
        //             'Authorization': 'Bearer yourAccessToken'
        //           });
        //
        // // Use tap for side effects in the observable chain.
        //           http.post(apiUrl, requestData, { headers, observe: 'body' }).pipe(
        //             tap(response => {
        //               console.log('POST Request was successful', response);
        //               // Handle the response body
        //             })
        //           ).subscribe(
        //             () => {
        //               // Additional processing can be done here
        //             },
        //             (error) => {
        //               console.error('POST Request failed', error);
        //               // Handle the error
        //             }
        //           );
        //
        // // Similarly, use tap for side effects with observe: 'response'.
        //           httpClient.post(apiUrl, requestData, { headers, observe: 'response' }).pipe(
        //             tap(response => {
        //               console.log('POST Request was successful', response);
        //               // Handle the full response object
        //             })
        //           ).subscribe(
        //             () => {
        //               // Additional processing can be done here
        //             },
        //             (error) => {
        //               console.error('POST Request failed', error);
        //               // Handle the error
        //             }
        //           );
        //         });

        const messagePromise = new Promise((resolve, reject) => {
          const options = {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'access-control-allow-origin': '*'
            },
            method: 'POST',
            body: data
          };
          console.log(options)
          fetch(urlString, options).then(response => {
            if (response.ok) {
              //console.log(response.statusText);
              console.log(response);
              if (isResponseProcessed) {
                resolve(response.json());
              } else {
                resolve(response);
              }
            }
          }).catch((e: HttpErrorResponse) => {
            console.log(e);
            if (e === undefined) {
              console.warn('No answer from control-service received. Resolving ...');
              resolve(true);
              return true;
            } else if (e.error === undefined || e.status === undefined) {
              alert('Netzwerkfehler: Zieladresse nicht erreichbar, Ressourcen nicht abrufbar: ' + e);
              reject(e);
              return (e);
            } else {
              console.log(e.status + ': ' + e.statusText);
              alert('Etwas hat nicht funktioniert: ' + e.error);
              reject(e.status);
              return e.status;
            }

          });
        });
        resolve(messagePromise);
      } else {
        console.log('data is undefined');
        reject('error on promise');
        //     }
        // });
      }
    });
  }

  sendAndRespondWithFake(data: any, url: string) {
    return new Promise((resolve, reject) => {

      let requiredCredentials =
      {
        "type": "de.kaprion.ppp.s2p.status.res",
        "oobid": "8c592713-7b8b-401c-89ea-208aef5ac194",
        "success": true,
        "detail": "",
        "attachments": {
          "id": "b2e06dd7-cbd8-4fef-a254-1b24feb598b4",
          "media_type": "application/ld+json",
          "data": {
            "json": {
              "@context": [
                "https://www.w3.org/2018/credentials/v1",
                "https://identity.foundation/presentation-exchange/submission/v1",
                "https://w3id.org/security/suites/jws-2020/v1"
              ],
              "type": [
                "VerifiablePresentation",
                "PresentationSubmission"
              ],
              "holder": "did:keri:Ne-sGIYdjue41gxtVWsx0GIIXL2du3I7LYLjSm7XL2tc?keyState=eyJ2IjoiS0VSSTEwSlNPTjAwMDExY18iLCJpIjoiTmUtc0dJWWRqdWU0MWd4dFZXc3gwR0lJWEwyZHUzSTdMWUxqU203WEwydGMiLCJzIjoiMCIsInQiOiJpY3AiLCJrdCI6IjEiLCJrIjpbIjFBQUFBNk52NElxWmxlbmFYT3pPdkplQnFJZXdiTTdLMGJ1eWY5YVVEeEswOW9qUCIsIjFBQUJBekswUWxNTHZ2MnhZNFl0LUotN3pXRHB5enJZOWRqYWtNcUJqeE10Q0ZxbiJdLCJuIjoiSU9GaWJmcGhNcDlnekFUdXNwdlNNVXI4UXJHYXNBMkpXRWpPeUl2c0M3VWMiLCJjIjpbIkVPIl0sInZuIjoiMDAuMDAifQ",
              "presentation_submission": {
                "id": "c7a2ec79-9207-4831-8fcb-4f1ae10ed099",
                "definition_id": "8246867e-fdce-48de-a825-9d84ec16c6c9",
                "descriptor_map": [
                  {
                    "id": "kdk_biometricphoto_input",
                    "format": "ldp_vp",
                    "path": "$.verifiableCredential[0]"
                  },
                  {
                    "id": "kdk_person_input",
                    "format": "ldp_vp",
                    "path": "$.verifiableCredential[1]"
                  },
                  {
                    "id": "kdk_address_input",
                    "format": "ldp_vp",
                    "path": "$.verifiableCredential[2]"
                  }
                ]
              },
              "verifiableCredential": [
                {
                  "@context": [
                    "https://www.w3.org/2018/credentials/v1",
                    "https://demo.shop.kaprion.net/credentialSubject/v6"
                  ],
                  "id": "https://demo.shop.kaprion.net/samples/KAPRION/KDKBirthCertificateK/afd0bd0d-d56a-4277-bd82-4e40c5131fd7",
                  "type": [
                    "VerifiableCredential",
                    "KDKBirthCertificateK"
                  ],
                  "issuer": "iss:REPLACE_ME_ISSUER",
                  "issuanceDate": "2024-05-23T06:31:47.505Z",
                  "expirationDate": "2025-05-23T06:31:47.505Z",
                  "credentialSubject": {
                    "birthDate": "1964-08-12",
                    "birthPlace": "Berlin",
                    "gender": "Female",
                    "parent": [
                      {
                        "givenName": "Max",
                        "familyName": "Mustermann",
                        "gender": "Male",
                        "sameAs": "did:keri:did:example:123"
                      },
                      {
                        "givenName": "",
                        "familyName": "",
                        "gender": "",
                        "sameAs": ""
                      }
                    ],
                    "isProtectedByCRE": true,
                    "id": "iss:REPLACE_ME_HOLDER",
                    "familyName": "Mustermann",
                    "givenName": "Erika"
                  }
                },
                {
                  "@context": [
                    "https://www.w3.org/2018/credentials/v1",
                    "https://demo.shop.kaprion.net/credentialSubject/v1",
                    "https://w3id.org/security/suites/jws-2020/v1"
                  ],
                  "id": "https://demo.shop.it.kaprion.net/samples/did:keri:NzxvS968GoPthjEuD-V2z5jN-sS3KXCTIK-FXputcDXg/KDKPersonK/7afe5124-e0df-45b7-ae2c-6dde5e7b405a",
                  "type": [
                    "VerifiableCredential",
                    "KDKPersonK"
                  ],
                  "issuer": "did:keri:NzxvS968GoPthjEuD-V2z5jN-sS3KXCTIK-FXputcDXg",
                  "issuanceDate": "2023-09-13T07:28:36.175Z",
                  "expirationDate": "2024-09-13T07:28:36.175Z",
                  "credentialSubject": {
                    "givenName": "Erika",
                    "familyName": "Mustermann",
                    "honorificPrefix": "Mr.",
                    "isProtectedByCRE": true,
                    "id": "did:keri:NjZOwJ-7vO3CNUY3eIdeOZhIAYlHkTSxM5CAOxc0QGRQ",
                    "birthName": "Röder"
                  },
                  "proof": {
                    "type": "JsonWebSignature2020",
                    "verificationMethod": "did:keri:NzxvS968GoPthjEuD-V2z5jN-sS3KXCTIK-FXputcDXg#key-0",
                    "created": "2023-09-13T07:30:18.599472Z",
                    "proofPurpose": "assertionMethod",
                    "jws": "eyJiNjQiOmZhbHNlLCJjcml0IjpbImI2NCJdLCJhbGciOiJFUzI1NksifQ..QLf5F7DZU8o54vXEqeDuDcwWMh6AywYuPYEwZSAQK_cdgqHCERARfye8NL0xL-Nd5lbmOIrL55bBMbfWCdDezQ"
                  }
                },
                {
                  "@context": [
                    "https://www.w3.org/2018/credentials/v1",
                    "https://demo.shop.kaprion.net/credentialSubject/v1",
                    "https://w3id.org/security/suites/jws-2020/v1"
                  ],
                  "id": "https://demo.shop.it.kaprion.net/samples/did:keri:NzxvS968GoPthjEuD-V2z5jN-sS3KXCTIK-FXputcDXg/KDKAddressK/4a51f695-379f-4284-9faa-55bbd73b176e",
                  "type": [
                    "VerifiableCredential",
                    "KDKAddressK"
                  ],
                  "issuer": "did:keri:NzxvS968GoPthjEuD-V2z5jN-sS3KXCTIK-FXputcDXg",
                  "issuanceDate": "2023-09-13T07:28:37.592Z",
                  "expirationDate": "2024-09-13T07:28:37.592Z",
                  "credentialSubject": {
                    "streetName": "Gostritzer Str.",
                    "residentSince": "2014-04-01",
                    "postalCode": "01217",
                    "houseNumber": "65",
                    "isProtectedByCRE": true,
                    "addressLocality": "Dresden",
                    "id": "did:keri:NjZOwJ-7vO3CNUY3eIdeOZhIAYlHkTSxM5CAOxc0QGRQ"
                  },
                  "proof": {
                    "type": "JsonWebSignature2020",
                    "verificationMethod": "did:keri:NzxvS968GoPthjEuD-V2z5jN-sS3KXCTIK-FXputcDXg#key-0",
                    "created": "2023-09-13T07:30:40.600078Z",
                    "proofPurpose": "assertionMethod",
                    "jws": "eyJiNjQiOmZhbHNlLCJjcml0IjpbImI2NCJdLCJhbGciOiJFUzI1NksifQ.._x_hNDv44FmV9MA6nN2xdfwPF5DKcxmoeHSWJAZI0bmLQxOJubag4HGz0cARh8bFNhxxPS_DhIrMEYvsFp-AOg"
                  }
                }
              ],
              "proof": {
                "type": "JsonWebSignature2020",
                "verificationMethod": "did:keri:Ne-sGIYdjue41gxtVWsx0GIIXL2du3I7LYLjSm7XL2tc#key-0",
                "created": "2022-12-01T13:27:35.028404Z",
                "proofPurpose": "assertionMethod",
                "jws": "eyJiNjQiOmZhbHNlLCJjcml0IjpbImI2NCJdLCJhbGciOiJFUzI1NksifQ..9ck0lq79sjLEFiE66zs0o8KPExEojSajg0aR7TzrACDvdGR-v4aR8m5R-QUcCEVOhVQGULCozWW2RZ18FEzTJw"
              }
            }
          },
          "format": "dif/presentation-exchange/submission@v1.0"
        }
      }
      let json = JSON.parse((data));
      let message = {
        "type": "de.kaprion.icp.s2p.issueCredential.req",
        "oobid": json['oobid'],
        "version": "0.1.0",
        "credentialApplication": requiredCredentials //contains all required VCs from holder
      }
      setTimeout(() => {
        resolve(message);
        return (message)
      }, 5000)
    });
  }

  sendIssuanceAndResponse(msg: any, url: string) {
    return new Promise((resolve, reject) => {
      let response: { [key: string]: any } = {
        ok: false,
        status: ""
      }
      setTimeout(() => {
        response['ok'] = true
        response['status'] = "ok"
        resolve(response)
        //return response
      }, 5000)
    })
  }

  sendExpectJSON(data: any, urlString: string): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(data)
      if (data !== undefined) {
        data = this.replacePlaceHolder(data);

        const messagePromise = new Promise((resolve, reject) => {
          const options = {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'access-control-allow-origin': '*'
            },
            method: 'POST',
            body: data
          };
          fetch(urlString, options).then(response => {
            if (response.body !== undefined && response.body instanceof ReadableStream) {
              return response.json()
            } else {
              return response
            }
          }).then(response => {
            if (response.ok) {
              //console.log(response.statusText);
              console.log(response);
            }
            resolve(response);
          })

            .catch((e: HttpErrorResponse) => {
              console.log(e);
              // resolving e === undefined for tests
              if (e === undefined) {
                console.warn('Keine Antwort vom Server erhalten');
                resolve(true);
                return true;
              } else if (e.error === undefined || e.status === undefined) {
                alert('Netzwerkfehler: Zieladresse nicht erreichbar, Ressourcen nicht abrufbar: ' + e);
                reject(e);
                return (e);
              } else {
                console.log(e.status + ': ' + e.statusText);
                alert('Etwas hat nicht funktioniert: ' + e.error);
                reject(e.status);
                return e.status;
              }

            });
        });
        resolve(messagePromise);
      } else {
        console.log('data is undefined');
        reject('error on promise');
        //     }
        // });
      }
    });
  }

  sendExpectJsonResponse(data: any, urlString: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let requiredCredentials =
      {
        "type": "de.kaprion.ppp.s2p.status.res",
        "oobid": "8c592713-7b8b-401c-89ea-208aef5ac194",
        "success": true,
        "detail": "",
        "attachments": {
          "id": "b2e06dd7-cbd8-4fef-a254-1b24feb598b4",
          "media_type": "application/ld+json",
          "data": {
            "json": {
              "@context": [
                "https://www.w3.org/2018/credentials/v1",
                "https://identity.foundation/presentation-exchange/submission/v1",
                "https://w3id.org/security/suites/jws-2020/v1"
              ],
              "type": [
                "VerifiablePresentation",
                "PresentationSubmission"
              ],
              "holder": "did:keri:Ne-sGIYdjue41gxtVWsx0GIIXL2du3I7LYLjSm7XL2tc?keyState=eyJ2IjoiS0VSSTEwSlNPTjAwMDExY18iLCJpIjoiTmUtc0dJWWRqdWU0MWd4dFZXc3gwR0lJWEwyZHUzSTdMWUxqU203WEwydGMiLCJzIjoiMCIsInQiOiJpY3AiLCJrdCI6IjEiLCJrIjpbIjFBQUFBNk52NElxWmxlbmFYT3pPdkplQnFJZXdiTTdLMGJ1eWY5YVVEeEswOW9qUCIsIjFBQUJBekswUWxNTHZ2MnhZNFl0LUotN3pXRHB5enJZOWRqYWtNcUJqeE10Q0ZxbiJdLCJuIjoiSU9GaWJmcGhNcDlnekFUdXNwdlNNVXI4UXJHYXNBMkpXRWpPeUl2c0M3VWMiLCJjIjpbIkVPIl0sInZuIjoiMDAuMDAifQ",
              "presentation_submission": {
                "id": "c7a2ec79-9207-4831-8fcb-4f1ae10ed099",
                "definition_id": "8246867e-fdce-48de-a825-9d84ec16c6c9",
                "descriptor_map": [
                  {
                    "id": "kdk_biometricphoto_input",
                    "format": "ldp_vp",
                    "path": "$.verifiableCredential[0]"
                  },
                  {
                    "id": "kdk_person_input",
                    "format": "ldp_vp",
                    "path": "$.verifiableCredential[1]"
                  },
                  {
                    "id": "kdk_address_input",
                    "format": "ldp_vp",
                    "path": "$.verifiableCredential[2]"
                  }
                ]
              },
              "verifiableCredential": [
                {
                  "@context": [
                    "https://www.w3.org/2018/credentials/v1",
                    "https://demo.shop.kaprion.net/credentialSubject/v6"
                  ],
                  "type": [
                    "VerifiableCredential",
                    "EUBankAccount"
                  ],
                  "issuer": "iss:REPLACE_ME_ISSUER",
                  "issuanceDate": "2024-06-04T14:10:12.350Z",
                  "credentialSubject": {
                    "id": "iss:REPLACE_ME_HOLDER",
                    "creditInstitution": "IDIdeal Bank",
                    "givenName": "Erika",
                    "familyName": "Mustermann",
                    "iban": "DE52802185009580676576",
                    "blz": "8021850",
                    "isProtectedByCRE": true
                  },
                  "id": "https://demo.shop.kaprion.net/samples/KAPRION/EUBankAccount/e53e6da9-8c3e-491b-b4d9-55bcf310572d",
                  "expirationDate": "2025-06-03T22:00:00.000Z",
                  "evidence": []
                }
              ],
              "proof": {
                "type": "JsonWebSignature2020",
                "verificationMethod": "did:keri:Ne-sGIYdjue41gxtVWsx0GIIXL2du3I7LYLjSm7XL2tc#key-0",
                "created": "2022-12-01T13:27:35.028404Z",
                "proofPurpose": "assertionMethod",
                "jws": "eyJiNjQiOmZhbHNlLCJjcml0IjpbImI2NCJdLCJhbGciOiJFUzI1NksifQ..9ck0lq79sjLEFiE66zs0o8KPExEojSajg0aR7TzrACDvdGR-v4aR8m5R-QUcCEVOhVQGULCozWW2RZ18FEzTJw"
              }
            }
          },
          "format": "dif/presentation-exchange/submission@v1.0"
        }
      }
      let json = JSON.parse((data));
      let message = {
        "type": "de.kaprion.icp.s2p.issueCredential.req",
        "oobid": json['oobid'],
        "version": "0.1.0",
        "credentialApplication": requiredCredentials //contains all required VCs from holder
      }
      setTimeout(() => {
        resolve(message);
        return (message)
      }, 5000)
    });
  }

  private replacePlaceHolder(data: any): any {
    let homeURL = this.envService.env['HOME_URL'];
    let tmp = data.replaceAll('__HOME_URL__', homeURL); // DEBUG procedure, reduce these lines in production to a single line
    console.log('Data getting send: ' + tmp);           // x
    return tmp;                                         // x
  }

  getRequestMethod(method: string, url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let xmlHttpReq = new XMLHttpRequest();
      xmlHttpReq.open(method, url);
      xmlHttpReq.onload = function () {
        if (xmlHttpReq.status >= 200 && xmlHttpReq.status < 300) {
          resolve(xmlHttpReq.response);
        } else {
          reject({
            'status': xmlHttpReq.status,
            'statusText': xmlHttpReq.statusText
          });
        }

        xmlHttpReq.onerror = () => {
          reject({
            'status': xmlHttpReq.status,
            'statusText': xmlHttpReq.statusText
          });
        };
      };
    });
  }

  getOutputDescriptors(data: any): Promise<any> {
    return new Promise((resolve) => {
      if (data !== undefined) {
        let uuid = crypto.randomUUID();
        let property = 'resp_' + uuid;
        if (Array.isArray(data['type']) && data['type'].length === 2) {
          let descriptorName = data['type'][1];
          let descriptorJson: Promise<any> = this.retrieveFile(window.location.origin + '/assets/outputdescriptors/' + descriptorName + '.json');
          descriptorJson.then(json => {
            //JSON.stringify(json);
            let requestObj = {
              'id': uuid,
              [property]: 'something',
              'verifiableCredential': data,
              'output_descriptors': json
            };
            resolve(requestObj);
          }).catch(error => {
            alert(error);
          });
        }
      }
    });
  }

  retrieveFile(urlString: string) {
    return new Promise((resolve, reject) => {
      fetch(urlString).then(result => {
        resolve(result.json());
        //return result
      }).catch(err => {
        console.log(err);
      });
    });
  }

  prepareMessage() {
    this.senderDid = 'did:';
  }
}
